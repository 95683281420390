import { useState } from "react"
import './accordion.css'

const Accordion = () => {
  const [clickId, setClickId] = useState({})
  const clickHandler = (id) => {
    setClickId({ ...clickId, [id]: !clickId[id] })
  }
  const total = [
    {
      title: 'What is this?',
      description: [
        <p>The Daily Market Exchange is an exclusive global investment club.</p>,
        <p>It is a forum for members to exchange <span>stock ideas, trading strategies,</span> and <span>market tips.</span></p>
      ]
    },
    {
      title: 'What can our members expect?',
      description: 'Profitable trade ideas. Consistent performance. Motivated community.'
    },
    {
      title: 'Need Proof? Of course you do!',
      description: 'Check our “Halls of Glory” page to see where our members have generated profits every month!'
    },
    {
      title: 'Our Goal?',
      description: [
        <p>To build a club of elite traders and investors, where members can benefit from each other’s unique trading skills and market experience.</p>,
        <p>All trade ideas, discussions, and profits/losses are tracked on the platform, <span>FOR ALL MEMBERS TO SEE</span>. The Daily Market Exchange was designed to enforce accountability, transparency, and credibility. All members have access to the “Current Trades” page.</p>
      ]
    },
    {
      title: 'How we do it?',
      description: [
        <p>Members retain their membership and access to the club by contributing their best trade ideas.</p>,
        <p>All members are ranked based on their contributions (e.g., stock tips, trade ideas, market insights). Those who persistently rank in the bottom 5% are kicked out. Below, you can find an explanation of how our ranking and points system works.</p>,
        <p>Over time, the quality of the club, as measured by the <span>profitability of our trades</span>, will only keep improving because only the most profitable and consistent members are retained in the club.</p>,
      ]
    },
    {
      title: 'How are we different from other online forums or public chat rooms?',
      description: [
        <p>Unlike other online forums, the Daily Market Exchange is a private network.  Only members are allowed to participate in the platform.</p>,
        <p>Membership at the Daily Market Exchange requires $49/month. We believe our membership fee creates an <span>important barrier to entry,</span> and allows the club to instantly filter out internet bots, trolls, and other low-level users who frequent public online forums.</p>,
        <p>We also believe this is the most effective self-selection mechanism to attract sophisticated, serious members looking to join a community of highly motivated investors and traders.</p>,
        <p>No lock-in contracts. You can cancel your membership at any time.</p>,
      ]
    },
    {
      title: 'How to become a member?',
      description: [
        <p>To ensure we maintain a tight-knit community, the club is currently capped at 950 members. Intake of new members will be subject to this cap.</p>,
        <p>We periodically <span>eliminate the bottom 5%</span> of members, creating capacity for new members to join. When the club is at capacity, new applicants will be placed on a waitlist, and will join the club on a first-come-first-served basis when a new spot opens up.</p>,
        <p><span>APPLY HERE</span> to secure your membership!</p>,
      ]
    },
  ]
  return (
    <>
      {total.map((item, i) => {
        return (
          <div className="accordion_item">
            <div className="accourdion_top" onClick={() => clickHandler(i)}>
              <h2 className={clickId[i] ? 'active' : ''}>{item.title}</h2>
              <i className={clickId[i] ? "fa fa-minus" : "fa fa-plus"}></i>
            </div>
            <div className={clickId[i] ? "accourdion_bottom active" : "accourdion_bottom"}>
              <p>{item.description}</p>
            </div>
          </div>
        )
      })
      }
    </>

  )
}
export default Accordion