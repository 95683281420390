import Header from "../helpers/header/header";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { url } from "../helpers/url";
import { Context } from "../helpers/context"
function SingleProfile() {
  const [user, setUser] = useContext(Context)
  const [portfolio, setPortfolio] = useState([])
  const [countPortfolio, setCountPortfolio] = useState(0)
  const addPortfolio = () => {
    setCountPortfolio(countPortfolio + 1)
    const dynamic = [...portfolio]
    dynamic.push(countPortfolio + 1)
    setPortfolio(dynamic)
    setPortfolioData({ ...portfolioData, [countPortfolio + 1]: {} })

  }
  const [portfolioData, setPortfolioData] = useState({})
  const portfolioChangeHandler = (e, id) => {

    let update = { ...portfolioData }
    update[id] = { ...update[id], [e.target.name]: e.target.value }
    setPortfolioData(update)
  }
  const updatePorfolio = (id) => {
    const dynamic = [...portfolio]
    let updateObj = { ...portfolioData }
    delete updateObj[id]
    const dynamicFilter = dynamic.filter(filterId => filterId !== id)
    setPortfolio(dynamicFilter)
    setPortfolioData(updateObj)
  }

  useEffect(() => {
    axios.get(url + '/portfolio/').
      then(({ data }) => {

        if (data.portfolio && data.portfolio.portfolio) {
          const parseJson = JSON.parse(data.portfolio.portfolio)
          const keys = Object.keys(parseJson)

          setPortfolioData(parseJson)
          setPortfolio(keys)
          setCountPortfolio(keys.length)
        }
        if (data.profile) {
          setProfileData(data.profile)
        }
      })
  }, [])

  const [portfolioSuccess, setPortfolioSuccess] = useState(false)
  const onSubmitPortfolio = (e) => {
    e.preventDefault()

    axios.post(url + '/portfolio/', { portfolio: portfolioData })
      .then(({ data }) => {
        if (data.success) {
          setPortfolioSuccess(true)
        }
      })
  }

  const [profileData, setProfileData] = useState({})
  const profileHandler = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value })
  }

  const [profileSuccess, setProfileSuccess] = useState(false)
  const [profileExist, setProfileExist] = useState(false)
  const [image, setImage] = useState({})
  const imageHandler = (e) => {
    setImage({ image: e.target.files[0], frontImage: URL.createObjectURL(e.target.files[0]) })
    setProfileData({ ...profileData, image: '' })

  }


  const onSubmitProfile = (e) => {
    e.preventDefault()
    const name = portfolioData.name
    const username = e.target.username.value
    const password = e.target.password.value
    const email = e.target.email.value
    const form = new FormData()
    form.append('name', profileData.name)
    form.append('username', profileData.username)
    form.append('password', profileData.password)
    form.append('email', profileData.email)
    form.append('image', image.image)
    axios.post(url + '/profile/update/', form)
      .then(({ data }) => {
        if (data.success) {
          setProfileSuccess(true)
          setProfileExist(false)
        }
        if (data.exist) {
          setProfileExist(true)
        }
      })
  }




  return (
    <>
      <Header className="about_header">
        <div className="col-lg-9 body_col">
          <div className="single_profile_wrapper">
            {user.userId && <>
              <form className="profile_info" onSubmit={onSubmitProfile}>
                <h2>Profile Info</h2>
                {
                  !profileSuccess && <>
                    <div class="form-group">
                      {
                        profileExist && <>
                          <div className="alert alert-danger">
                            This email is already exist on our record.
                          </div>
                        </>
                      }

                      <label for="email">Email</label>
                      <input value={profileData['email']} onChange={profileHandler} name="email" type="email" class="form-control " id="email" placeholder="Enter your email" />
                    </div>
                    <div class="form-group">
                      <label for="Username">Name</label>
                      <input onChange={profileHandler} name="name" required type="text" class="form-control" id="Username" placeholder="Enter your Name" value={profileData['name']} />
                    </div>
                    <div class="form-group">
                      <label for="Username">Username</label>
                      <input value={profileData['username']} onChange={profileHandler} name="username" required type="text" class="form-control" id="Username" placeholder="Enter your username" />
                    </div>

                    <div class="form-group">
                      <label for="password">Password</label>
                      <input onChange={profileHandler} name="password" type="password" class="form-control" id="password" placeholder="Enter your password" />
                    </div>
                    <div class="form-group">
                      <label for="password">Profile Picture</label>
                      <input onChange={imageHandler} name="image" type="file" class="form-control" id="password" />

                      {/* <div className="img_box" onClick={openImg}>
                    <i className="fa fa-image"></i>
                  </div> */}
                      {(!profileData.image && image.frontImage) &&
                        <div className="img_box">
                          <img src={image.frontImage} alt="" />
                        </div>
                      }
                      {(profileData.image) &&
                        <div className="img_box">
                          <img src={profileData.image} alt="" />
                        </div>
                      }

                    </div>
                    <div class="form-group">
                      <button className="btn btn-info" type="submit">Update Profile</button>
                    </div>
                  </>
                }
                {
                  profileSuccess && <>
                    <div className="alert alert-success">
                      Your profile has been updated.
                    </div>
                  </>
                }

              </form>
              <form className="protfolio_info" onSubmit={onSubmitPortfolio}>
                <h2>Portfolio Info</h2>
                {portfolioSuccess && <div className="alert alert-success">
                  Your Portfolio has been updated.
                </div>}
                {!portfolioSuccess && portfolio.map((item, i) => {
                  return (
                    <>
                      <div className="portfolio_group">
                        <div className="close">
                          <i className="fa fa-close" onClick={() => updatePorfolio(item)}></i>
                        </div>
                        <div class="form-group">
                          <label for="Username">Investment Name e.g., Tesla, Apple, Bitcoin, SP500 Index ETF</label>
                          <input onChange={(e) => portfolioChangeHandler(e, item)} required type="text" name="name" class="form-control" id="Username" placeholder="Enter your Porfolio Name" value={portfolioData[item].name} />
                        </div>
                        <div class="form-group">
                          <label for="Username">Portfolio Percentage (%)</label>
                          <input onChange={(e) => portfolioChangeHandler(e, item)} required type="number" name="value" class="form-control" id="Username" placeholder="Enter your asset amount" value={portfolioData[item].value} />
                        </div>
                      </div>
                    </>
                  )
                })}
                {!portfolioSuccess && <>
                  <a onClick={addPortfolio} className="btn btn-info add_porfolio"><i className="fa fa-plus"></i> Add Portfolio</a>
                  <div class="form-group">
                    <button type="submit" className="btn btn-info update">Update Portfolio</button>
                  </div>
                </>}
              </form>

            </>}
          </div>
        </div>
      </Header>
    </>
  );
}

export default SingleProfile;