
import { useState, useEffect } from "react"

const Pagination = ({ count, row, clickId }) => {
  const [pagiantion, setPagination] = useState([])
  useEffect(() => {
    setPagination(count)
  }, [count])

  const [current, setCurrent] = useState(0)
  const [old, setOld] = useState(0)
  // const [next, setNext] = useState(0)
  const clickHandler = (id) => {
    setOld(current)
    setCurrent(id)
    clickId(id)
  }

  return (
    <div className="row">
      <div className="col">
        <nav aria-label="...">
          <ul class="pagination">
            <li className={current > 0 ? "page-item" : "page-item disabled"}>
              <a className="page-link" onClick={() => clickHandler(count.length === old ? 0 : old)} tabindex="-1">Previous</a>
            </li>
            {
              pagiantion.map(item => <li className={current === item ? "page-item active" : "page-item"}><a className="page-link" onClick={() => clickHandler(item)}>{item}</a></li>)
            }
            {row !== 0 && <li class="page-item">
              <a className="page-link" onClick={() => clickHandler(current + 1)}>Next</a>
            </li>}

          </ul>
        </nav>

      </div>
    </div>

  )
}
export default Pagination