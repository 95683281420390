import Header from '../../helpers/header/header'
import Feed from '../feed/feed'
import Model from '../model/model';
import ModelReadMore from '../model/read_more_model';
import ModelRetweet from '../model/retweet';
import { useState, useEffect, useContext } from 'react'
import { url } from '../../helpers/url';
import axios from 'axios';
import { Context } from "../../helpers/context"
import { Toastify } from '../toastify/toastify'
function Home() {
  const [loginpopup, setLoginpopup] = useState(false)
  const [open, setOpen] = useState('none')
  // const [open, setOpen] = useState('retweet')

  const getModel = (item) => {
    setOpen(item)
  }
  const [post, setPost] = useState([])
  const getPost = (item) => {
    setPost([item, ...post])
  }

  useEffect(() => {
    axios.get(url + '/feed?offset=0')
      .then(({ data }) => {
        if (data.success) {
          setPost(data.rows)
        }
      })
  }, [])

  const [user, setUser] = useContext(Context)

  const modelHandler = () => {
    setUser({ ...user, model: true })
  }

  return (
    <>

      <Header>
        <div className="col-lg-9 body_col">
          {!user.userId && <>
            <div className="banner">
              <div className="banner_left">
                <img src="stock.png" alt="" />
              </div>
              <div className="banner_center">
                <p>Got a new stock idea? Login here to post</p>
              </div>
              <div className="banner_right">
                <a href="/singup"><img src="member.png" alt="" />Join the discussion!</a>
              </div>
            </div>
          </>}

          {user.userId && <>
            <div className="write_post">
              <div className="banner_left">
                <img src="stock.png" alt="" />
              </div>
              <div className="left">  <p>Got a hot take? Share your latest analysis with the community!</p></div>
              <div className="right"> <button className='btn btn-light' onClick={modelHandler}>Write Post</button></div>
            </div>

          </>}
          <div className="front_feed_wrapper">
            <Feed type="post" data={post} />
          </div>

          {/* {!user.userId && <div className="alert alert-danger">
            <p>You need to <a href="/signin">Sing In</a></p>
          </div>} */}

        </div>
      </Header>

      {/* {user.retweeted && <Toastify />}
      {user.retweet && <ModelRetweet style={open} data={getModel} sendPost={getPost} />} */}
      {/* {user.model && <Model data={getModel} sendPost={getPost} />} */}
      {user.loginPopup && <Model data={getModel} sendPost={getPost} type={"login"} />}
      {user.read_more && <ModelReadMore />}

    </>
  );
}

export default Home;