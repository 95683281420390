import { useState, useEffect } from 'react'
import Layout from '../../../admin-component/layout'
import { url } from '../../../helpers/url'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
function CouponCom({ mode }) {
  const editId = useParams()
  const [cat, setCat] = useState({})
  const [load, setLoad] = useState(false)
  useEffect(() => {
    if (editId.id) {
      axios.get(`${url}/post/single?postId=${editId.id}`).then(({ data }) => {
        if (data.success && data.rows.length > 0) {
          setCat(data.rows[0])
          setLoad(true)
        }
      })
    }
  }, [editId.id])


  const onType = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value })
  }

  const [success, setSuccess] = useState(false)
  const couponHandler = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("gain", cat.gain)
    data.append("feature_text", cat.feature_text)
    data.append("feature", (cat.gain && cat.feature_text) ? 1 : 0)
    data.append("mode", "glory")
    data.append("id", cat.id)
    axios.post(`${url}/admin/post/`, data).then(({ data }) => {
      if (data.success) {
        setSuccess(true)
      }
    })
  }

  return (
    <Layout>
      <div className="admin_form">
        <h3 className='heading mb-2'>Glory Post</h3>

        {(success) &&
          <div className="alert alert-success">Glory post has been updated</div>
        }
        {(!success && load) &&
          <form onSubmit={couponHandler}>
            <div className="input_items">
              <div className="input_group">
                <label htmlFor="">Market Call</label>
                <input onChange={onType} type="number" name="gain" id="" value={cat.gain} />
              </div>
              <div className="input_group">
                <label htmlFor="">Description</label>
                <textarea onChange={onType} name="feature_text" id="" value={cat.feature_text} ></textarea>
              </div>
              {/* <div className="input_group">
                <label htmlFor="">Glory Page</label>
                <select name="feature" id="">
                  <option selected={cat.feature === 0} value="0">No</option>
                  <option selected={cat.feature === 1} value="0">Yes</option>

                </select>
              </div> */}


              <div className="input_group">
                <Link target="__blank" className='btn btn-success' to={"/post/" + cat.id}><span className='capitalize'>View Post</span> </Link>
              </div>
              <div className="input_group">
                <button className='admin_btn'><span className='capitalize'>Make Glory Post</span> </button>
              </div>
            </div>
          </form>
        }
      </div>
    </Layout>
  );
}

export default CouponCom;