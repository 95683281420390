import Header from '../../helpers/header/header'

import Feed from '../../components/feed/feed'
import { useState, useEffect, useContext } from 'react'
import { url } from '../../helpers/url';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Context } from '../../helpers/context';
import Model from '../../components/model/model';
import ModelReadMore from '../../components/model/read_more_model';
function Tags() {
  const [load, setLoad] = useState(false)
  const router = useParams()
  const [post, setPost] = useState([])
  const [user, setUser] = useContext(Context)
  useEffect(() => {
    if (router.id) {
      axios.get(url + `/profile?offset=0&tag=${router.id}`)
        .then(({ data }) => {
          if (data.success) {
            setPost(data.rows)
          }
          setLoad(true)
        })
    }

  }, [router])
  return (
    <>
      <Header>
        <div className="col-lg-9 body_col">
          {(load && post.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && post.length > 0) && <>

            <div className="custom_alert alert alert-success">
              Search Result:
            </div>
            <Feed type="post" data={post} />
          </>

          }


        </div>
      </Header>
      {user.loginPopup && <Model style={"block"} data={() => { }} type={"login"} />}
      {user.read_more && <ModelReadMore />}
    </>
  );
}

export default Tags;