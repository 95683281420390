import Layout from '../../admin-component/layout'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from "../../helpers/url";
import Pagination from '../../admin-component/pagination';
function ViewUsers() {
  const [row, setRow] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [count, setCount] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [load, setLoad] = useState(false)
  useEffect(() => {
    axios.get(`${url}/admin/user?page=0&mode=view`).then(({ data }) => {
      if (data.success) {
        setRow(data.rows)
        setOldRow(data.rows)
        setCount(data.count)
        setOldCount(data.count)
        setLoad(true);
      }
    })
  }, [])

  const postHandler = (id) => {
    axios.post(`${url}/admin/user/`, { mode: 'delete', id: id }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id !== id)
        setRow(filter)
      }
    })
  }
  function getPaginationId(id) {
    setLoad(false)
    const page = id > 0 ? id * 20 : 0
    axios.get(`${url}/admin/user?page=${page}&mode=view`)
      .then(({ data }) => {
        setRow(data.rows);
        setOldRow(data.rows)
        setCount(data.count);
        setOldCount(data.count);
        setLoad(true);
      })
  }


  const chnageHandler = (e, id, arrayId) => {
    const status = e.target.value
    axios.post(`${url}/admin/user/`, { mode: 'edit', id: id, status: status }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id === id)
        let update = [...row]
        update[arrayId].status = status
        setRow(update)
      }
    })
  }

  const [timer, setTimer] = useState(null);

  const changeDelay = (change) => {

    if (change.length) {

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          axios.get(`${url}/admin/search?search=${change}&type=user`)
            .then(({ data }) => {
              setRow(data.rows)
              setCount(data.count);
            });
        }, 1000)
      );
    }
    if (change.length === 0) {
      setRow(oldRow)
      setCount(oldCount)
    }
  }

  const [point, setPoint] = useState({})
  const [pointClick, setPointClick] = useState({})
  const pointHandler = (id) => {
    axios.get(url + `/user/point?id=` + id)
      .then(({ data }) => {
        if (data.success) {
          setPoint({ ...point, [id]: data.point })
          setPointClick({ ...pointClick, [id]: true })
        }
      })
  }

  return (
    <Layout>

      <div className="admin_table">
        <div className="admin_body_header">
          <div className="left_header">
            <h3>User List</h3>
          </div>
          <div className="right_header">
            <input onChange={(e) => changeDelay(e.target.value)} type="text" name="" id="" placeholder='Search user' />
          </div>

        </div>

        {
          load && row.length === 0 && <div className="alert alert-danger">
            No user is found
          </div>
        }

        <table class="table table-hover table-striped left_table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Username</th>
              <th>Status Select</th>
              <th>Point</th>
              {/* <th>Rank</th> */}
              <th>View User</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {row.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{item.email}</td>
                  <td>{item.username}</td>
                  <td><select name="" id="" onChange={(e) => chnageHandler(e, item.id, index)}>
                    <option selected={item.status === 'disabled'} value="disabled">Disabled</option>
                    <option selected={item.status === 'approved'} value="approved">Approved</option>
                  </select></td>
                  {(pointClick[item.id])
                    ?
                    <td><button className='btn btn-success point_button'>{point[item.id]}</button></td>
                    :
                    <td><button className='btn btn-success point_button' onClick={() => pointHandler(item.id)}>View Point</button></td>
                  }


                  {/* <td><Link className='btn btn-danger' target={"_blank"} to={"/admin/user/" + item.id}>Rank</Link></td> */}

                  <td><Link className='btn btn-warning' target={"_blank"} to={"/profile/" + item.id}>View User</Link></td>
                  <td className='cursor' onClick={() => postHandler(item.id)}><p className='btn btn-dark'>Delete</p></td>
                </tr>
              )
            })}

            {count.length > 0 && <Pagination row={row.length} count={count} clickId={getPaginationId} />}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewUsers;