import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./toastify.css"
export function Toastify({ content }) {
	return (
		<div>
			<ToastContainer />
		</div>
	)
}
export function Toast(content) {
	return toast(content)
}
