import { url } from "../helpers/url";
import axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import { Home, Box, DollarSign, Tag, Clipboard, Camera, AlignLeft, UserPlus, Users, Chrome, BarChart, Settings, Archive, LogIn, Layout, Bell, List } from "react-feather"
import { Context } from "../helpers/context"
function Header({ children, extraClass }) {

  const [sub, setSub] = useState('')
  const addSubmenu = (id) => {
    sub === id ? setSub('') : setSub(id)
  }
  const menu = [
    {
      title: 'Home',
      icon: <Box size={16} />,
      link: '/'
    },
    {
      title: 'Tag',
      link: '/admin',
      icon: <Box size={16} />,
    },
    {
      title: 'Profile',
      link: '/admin/profile',
      icon: <Box size={16} />,
    },

    {
      title: 'Post',
      link: '/admin/post',
      icon: <Box size={16} />,
    },
    {
      title: 'Comment',
      link: '/admin/comment',
      icon: <Box size={16} />,
    },
    {
      title: 'User',
      link: '/admin/user',
      icon: <Box size={16} />,
    },
    {
      title: 'Ranking',
      link: '/admin/rank',
      icon: <Box size={16} />,
    },
    {
      title: 'Trade',
      link: '/admin/trade',
      icon: <Box size={16} />,
    },
    {
      title: 'Logout',
      link: '/admin/logout',
      icon: <Box size={16} />,
    },
  ]
  const [mobile, setMobile] = useState(false)
  const showMenu = () => {
    const curMobile = !mobile
    setMobile(!mobile)

  }

  const ref = useRef(null)
  const [user, setUser] = useContext(Context)
  const [admin, setAdmin] = useState(false)
  useEffect(() => {
    axios.get(url + '/admin/')
      .then(({ data }) => {
        if (!data.success) {
          window.location.href = '/'
        } else {
          setAdmin(true)
        }
      })

    if (window.screen.width > 991) {
      setMobile(true)
    } else {
      window.addEventListener('click', (e) => {
        if (ref.current && mobile && e.target.className !== 'fa fa-bars' && !ref.current.contains(e.target)) {
          setMobile(false)
        }
      })
    }
  }, [])
  return (
    <div className={"admin_wrapper " + extraClass}>
      {admin &&
        <div className="admin_body">
          <div className="left">
            <div className="logo_wrapper">
              <a href="/">
                <div className="logo">
                  <p>Daily Market</p>
                  <p>EXCHANGE</p>
                </div>
              </a>
              <div className="icon">
                <i className="fa fa-bars" onClick={showMenu}></i>
              </div>
            </div>
            {
              mobile &&

              <div className="admin_menu" ref={ref}>
                {
                  menu.map((item, i) => {
                    if (item.sub) {
                      return (
                        <div className="admin_menu_item" onClick={() => addSubmenu(i)}>
                          <div className="menu_wrapper toggle_menu">
                            <span>{item.icon}</span>
                            <a className="main">{item.title} <i className={sub === i ? "fa fa-angle-up" : "fa fa-angle-down"}></i></a>
                          </div>
                          {sub === i && item.sub.map(item => {
                            return (
                              <div className="admin_sub_menu menu_wrapper">
                                <a href={item.link}>{item.title}</a>
                              </div>
                            )
                          })}

                        </div>
                      )
                    } else {
                      return (
                        <a className="no_toggle_main" href={item.link}>
                          <div className="admin_menu_item menu_wrapper">
                            <span>{item.icon}</span>
                            <span>{item.title}</span>
                          </div>
                        </a>
                      )
                    }

                  })
                }
              </div>
            }
          </div>
          <div className="right">
            <div className="admin_top">
              <div className="right">
                <p>Welcome to Admin Panel</p>
              </div>
            </div>
            {children}
          </div>
        </div>
      }
    </div>

  );
}

export default Header;