import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { url } from "../../helpers/url";
import { feedTime, substring, screen } from "../../helpers/functions";
import { Context } from "../../helpers/context";
import { Link, useNavigate } from "react-router-dom";
import { socket } from "../../helpers/socket"
import { Toastify, Toast } from '../toastify/toastify'
import ReactHtmlParser from "react-html-parser"
import { toast } from "react-toastify";




// const getTime = (dt) => new Date(dt)
// const today = new Date()



function Post({ type, data, edit, admin, follow, isTracking }) {
  const location = useNavigate()
  const [height, setHeight] = useState('80%')

  useEffect(() => {
    if (document.querySelector('.user')) {
      const height = document.querySelector('.user').clientHeight
      setHeight(height - 50)
    }
  })




  const [postId, setPostId] = useState([])

  const readMore = (item) => {
    if (item.read_more && !user.userId) {
      setUser({ ...user, read_more: true })
    } else {
      setPostId([...postId, item.id])
    }
  }

  const [post, setPost] = useState([])
  useEffect(() => {
    setPost(data)
    if (window.location.href.includes("#comment_id") && data && data.length > 0) {
      getComment(data[0].id)
    }
    if (window.location.href.includes("#reply_id") && data && data.length > 0) {
      getComment(data[0].id)
      const split = window.location.hash.split("?")
      const replyId = split[0]
      const commentId = split[1].split("=")[1]
      boxHandler(data[0].id, 'reply', { id: commentId })
    }
  }, [data])


  const [isLike, setIsLike] = useState({})
  const likeHandler = (id, type, arrayId, mainUserId) => {
    if (!user.userId) {
      location('/signin')
    } else {

      axios.post(url + '/post/like/', { postId: id, type: type, mainUserId: mainUserId })
        .then(({ data }) => {

          let updatePost = [...post]
          if (data.status === 'create') {
            updatePost[arrayId][type] = updatePost[arrayId][type] + 1
            updatePost[arrayId]['post_likes.type'] = type
          } else if (data.status === 'update' && data.type === 'like') {
            updatePost[arrayId]['like'] = updatePost[arrayId]['like'] + 1
            updatePost[arrayId]['dislike'] = updatePost[arrayId]['dislike'] - 1
            updatePost[arrayId]['post_likes.type'] = 'like'
          }
          else if (data.status === 'update' && data.type === 'dislike') {
            updatePost[arrayId]['dislike'] = updatePost[arrayId]['dislike'] + 1
            updatePost[arrayId]['like'] = updatePost[arrayId]['like'] - 1
            updatePost[arrayId]['post_likes.type'] = 'dislike'
          }
          else {
            updatePost[arrayId][type] = updatePost[arrayId][type] - 1
            updatePost[arrayId]['post_likes.type'] = ''
          }
          if (user.userId !== post[arrayId].userId) {

            socket.emit("sendMessage", { postId: post[arrayId].id, receiverId: post[arrayId].userId, type: "post_like", userId: user.userId, like: updatePost[arrayId]['like'], dislike: updatePost[arrayId]['dislike'], title: updatePost[arrayId]['title'] })
          }

          setPost(updatePost)
        })
    }
  }


  const [commentText, setCommentText] = useState({})
  const commentHandler = (e, type) => {
    setCommentText({ ...commentText, [type]: e.target.value })
  }

  const [isBox, setIsbox] = useState({})
  const [comment, setComment] = useState({})
  const [reply, setReply] = useState({})
  const [countComment, setCountComment] = useState(0)
  const [user, setUser] = useContext(Context)
  const [replyToUser, setReplyToUser] = useState()
  const boxHandler = (id, type, cmt) => {
    if (!user.userId) {
      location('/signin')
    } else {
      const cmtId = cmt.id
      setReplyToUser(cmt.userId)
      setIsbox({ [id]: type })
      if (type === 'reply') {
        setIsbox({ [cmtId]: type })
        axios.get(url + `/post/comment?postId=${id}&type=${'reply'}&replyId=${cmtId}&offset=0`)
          .then(({ data }) => {
            if (data.success) {
              let update = { ...reply }
              update[cmtId] = {
                rows: data.rows,
                count: data.count
              }
              setReply(update)

            }
          })
      }
      if (type === 'comment') {
        setIsbox({ [id]: type })
        getComment(id)
      }
    }
  }

  const getComment = (id) => {
    if (!user.userId) {
      location('/signin')
    } else {
      axios.get(url + `/post/comment?postId=${id}&type=${'comment'}&offset=0`)
        .then(({ data }) => {
          if (data.success) {
            let update = { ...comment }
            update[id] = {
              rows: data.rows,
              count: data.count
            }
            setComment(update)

          }
        })
    }
  }

  const [mount, setMount] = useState(true)
  useEffect(() => {
    if (Object.keys(comment).length > 0 && mount && window.location.href.includes("#comment_id") && document.querySelector(window.location.hash)) {
      const id = document.querySelector(window.location.hash)
      const height = id.clientHeight
      window.scrollTo(0, id.offsetTop - height)
      setMount(false)
    }

    else if (Object.keys(comment).length > 0 && mount && window.location.href.includes("#reply_id")) {
      const split = window.location.hash.split("?")
      const replyId = split[0]
      const commentId = split[1].split("=")[1]
      if (document.querySelector(replyId)) {
        const id = document.querySelector(replyId)
        const height = id.clientHeight

        window.scrollTo(0, id.offsetTop - height)
        setMount(false)
      }

    }

  }, [comment])



  const imgRef = useRef([])
  const imageUpload = (id) => {
    imgRef.current[id].click()
  }
  const [cmtImg, setcmtImg] = useState({})
  const cmtImgChange = (e, type) => {
    setcmtImg({ [type]: e.target.files[0] })
  }
  const cmtImgClose = () => {
    setcmtImg('')
  }

  const splitComment = (value) => {
    const newTextPortions = value.split('\n');
    let newTextStr = ''
    newTextPortions.forEach(function (portion) {
      newTextStr += "<p>" + portion + "</p>"
    })
    return newTextStr
  }

  const postComment = (item, type, mainCmt = {}) => {
    const cmtId = mainCmt.id
    const id = item.id
    if (!user.userId) {
      location('/signin')
    } else {
      if (commentText[type] || cmtImg[type]) {
        let notfiyComment = ''
        const formData = new FormData()
        formData.append('postId', id)
        if (commentText[type]) {
          if (type === 'comment') {
            notfiyComment = splitComment(commentText[type])
          } else {
            notfiyComment = mainCmt.comment
          }
          formData.append('comment', splitComment(commentText[type]))
        }
        formData.append('type', type)
        formData.append('replyId', cmtId)
        if (cmtImg[type]) {
          formData.append('image', cmtImg[type])
        }

        setcmtImg({})
        axios.post(url + '/post/comment/', formData)
          .then(({ data }) => {
            if (data.success) {
              if (type === 'comment') {
                let update = { ...comment }
                update[id]['rows'] = [{ ['registers.userImg']: user.image, ['registers.username']: user.username, userId: user.userId, ...data.comment, image: data.comment.image }, ...comment[id].rows]
                setComment(update)
                setCommentText({ ...commentText, [type]: '' })
                if (user.userId !== item.userId) {
                  socket.emit("sendMessage", { postId: item.id, title: item.title, receiverId: item.userId, type: type, userId: user.userId, cmtId: data.comment.id, comment: notfiyComment })
                }
              }
              if (type === 'reply') {
                let update = { ...reply }
                update[cmtId]['rows'] = [{ ['registers.userImg']: user.image, ['registers.username']: user.username, userId: user.userId, ...data.comment }, ...reply[cmtId].rows]
                setReply(update)
                setCommentText({ ...commentText, [type]: '' })

                let mainUserId = replyToUser
                if (replyToUser === user.userId) {
                  mainUserId = item.userId
                }
                if (!mainUserId) {
                  mainUserId = item.userId
                }
                if (mainUserId !== user.userId) {
                  socket.emit("sendMessage", { postId: item.id, title: item.title, receiverId: mainUserId, type: type, userId: user.userId, cmtId: data.comment.id, repId: parseInt(data.comment.replyId), comment: notfiyComment })
                }
              }
            }
          })
      }
    }
  }

  const cmtLikeHandler = (id, type, arrayId, commentType, replyId, item, mainUserId) => {
    if (!user.userId) {
      location('/signin')
    } else {
      let dyUrl = url + '/post/comment/like'

      let data = { commentId: replyId, type: type, postId: id, mainUserId: mainUserId }
      let updatePost = { ...comment }
      if (commentType === 'reply') {
        updatePost = { ...reply }
        dyUrl = url + '/post/comment/reply/like'
        // data = { commentId: replyId, type: type, postId: id, mainUserId: mainUserId }
      }

      let dyVariable = updatePost[id]['rows'][arrayId]

      axios.post(dyUrl, data)
        .then(({ data }) => {
          if (data.status === 'create') {
            dyVariable[type] = dyVariable[type] + 1
            dyVariable['comment_likes.type'] = type
          } else if (data.status === 'update' && data.type === 'like') {
            dyVariable['like'] = dyVariable['like'] + 1
            dyVariable['dislike'] = dyVariable['dislike'] - 1
            dyVariable['comment_likes.type'] = 'like'
          }
          else if (data.status === 'update' && data.type === 'dislike') {
            dyVariable['dislike'] = dyVariable['dislike'] + 1
            dyVariable['like'] = dyVariable['like'] - 1
            dyVariable['comment_likes.type'] = 'dislike'

          }
          else {
            dyVariable[type] = dyVariable[type] - 1
            dyVariable['comment_likes.type'] = ''
          }
          if (commentType === 'reply') {
            setReply(updatePost)
          }
          if (commentType === 'comment') {
            setComment(updatePost)
          }


          let likeId = {}

          if (commentType === 'reply') {
            const key = Object.keys(isBox)
            likeId = { cmtId: replyId, repId: key[0] }
          } else {
            likeId = { cmtId: replyId }
          }


          if (user.userId !== dyVariable.userId) {
            socket.emit("sendMessage", { postId: item.id, receiverId: dyVariable.userId, type: commentType === 'comment' ? "comment_like" : "reply_like", userId: user.userId, like: dyVariable['like'], dislike: dyVariable['dislike'], title: item.title, ...likeId, comment: dyVariable.comment })
          }
        })

    }
  }

  const sendPolls = (id, type, arrayId) => {


    let updatePost = [...post]
    if (!user.userId) {
      location('/signin')
    } else if (post[arrayId].end_time >= new Date().getTime()) {
      axios.post(url + '/post/like/', { postId: id, type: type })
        .then(({ data }) => {
          if (data.status === 'create') {
            updatePost[arrayId][type] = updatePost[arrayId][type] + 1
            // updatePost[arrayId]['comment_likes.type'] = type
            updatePost[arrayId]['type'] = 'Answer'
            updatePost[arrayId]['post_likes.type'] = type
          } else if (data.status === 'update' && data.type === 'like') {
            updatePost[arrayId]['like'] = updatePost[arrayId]['like'] + 1
            updatePost[arrayId]['dislike'] = updatePost[arrayId]['dislike'] - 1
            updatePost[arrayId]['comment_likes.type'] = 'like'
            updatePost[arrayId]['type'] = 'Answer'
            updatePost[arrayId]['post_likes.type'] = 'like'
          }
          else if (data.status === 'update' && data.type === 'dislike') {
            updatePost[arrayId]['dislike'] = updatePost[arrayId]['dislike'] + 1
            updatePost[arrayId]['like'] = updatePost[arrayId]['like'] - 1
            // updatePost[arrayId]['comment_likes.type'] = 'dislike'
            updatePost[arrayId]['type'] = 'Answer'
            updatePost[arrayId]['post_likes.type'] = 'dislike'
          }
          else {
            updatePost[arrayId][type] = updatePost[arrayId][type] - 1
            // updatePost[arrayId]['comment_likes.type'] = ''
            updatePost[arrayId]['post_likes.type'] = ''

          }
          setPost(updatePost)
        })
    } else {
      updatePost[arrayId]['closed'] = true
      setPost(updatePost)
    }
  }

  const sendRequest = (id, arrayId) => {
    if (!user.userId) {
      location('/signin')
    } else {
      axios.post(url + '/follow/', { receiverId: id })
        .then(({ data }) => {
          if (data.success) {
            let updatePost = [...post]
            updatePost[arrayId].follow = true
            setPost(updatePost)
          }
        })
    }
  }
  const sendTrack = (tags, postId, arrayId, type) => {
    if (!user.userId) {
      location('/signin')
    } else {
      axios.post(url + '/track/', { tags: tags, postId: postId, type: type })
        .then(({ data }) => {
          if (data.success && type === 'add') {
            let updatePost = [...post]
            updatePost[arrayId].tags = false
            setPost(updatePost)
          }
          if (data.success && type === 'delete') {
            const updatePost = post.filter(item => item.id !== postId)
            isTracking(updatePost)
          }
        })
    }
  }

  const [hasMore, setHasMore] = useState(true)
  const [once, setOnce] = useState(false)
  const findItem = () => {

    if (hasMore && post.length === 20 && user.userId) {
      setHasMore(false)
      axios.get(`${url}/feed?offset=` + post.length).then(({ data }) => {
        if (data.success && data.rows.length > 0) {
          setPost([...post, ...data.rows])
          setHasMore(true)
        }
        if (data.rows.length === 0 && data.success) {
          setHasMore(false)
        }
      })
    }
    if (hasMore && !user.userId && !once) {
      setOnce(true)
      setUser({ ...user, loginPopup: true })
    }

  }


  const [editmenu, setEditmenu] = useState(false)
  const editRef = useRef(null)
  const postEditMenu = (postId) => {
    if (editmenu === false) {
      setEditmenu(postId)
    } else {
      setEditmenu(false)
    }
  }

  const postEditHandler = (postId, arrayId, type) => {


    if (type === 'delete') {
      axios.post(`${url}/post/`, { id: postId, category: 'delete' }).then(({ data }) => {
        if (data.success) {
          const filter = post.filter(item => item.id !== postId)
          setPost(filter)
          setEditmenu(false)
        }
      })
    }
    if (type === 'edit') {
      edit(post[arrayId])
      setEditmenu(false)
    }
  }

  const [textarearow, setTextarearow] = useState(3)
  const [expand, setExpand] = useState(true)

  const textAreaHandler = (id) => {
    if (expand) {
      setTextarearow(textarearow + 4)
      setExpand(false)
    } else {
      setTextarearow(textarearow - 4)
      setExpand(true)
    }

  }

  const cmtSuperLikeHandler = async (id, type, arrayId, commentType, replyId, item, mainUserId) => {
    if (!user.userId) {
      location('/signin')
    }
    else if (user.userId && mainUserId === user.userId) {
      // const isComment = commentType === 'reply' ? 'Reply' : 'Comment'
      Toast("You can't DIAMOND UPVOTE your own " + commentType)
      setNotification(true)
    }
    else {
      let dyUrl = url + '/post/comment/like/super'

      let data = { commentId: replyId, type: type, postId: id, mainUserId: mainUserId }
      let updatePost = { ...comment }
      if (commentType === 'reply') {
        updatePost = { ...reply }
        dyUrl = url + '/post/comment/reply/super'
        // data = { commentId: replyId, type: type, postId: id }
      }
      let dyVariable = updatePost[id]['rows'][arrayId]

      axios.post(dyUrl, data)
        .then(({ data }) => {

          if (data.success) {

            if (data.status === 'create') {
              dyVariable["super_like"] = dyVariable["super_like"] + 1
              dyVariable['comment_super_likes.userId'] = true
            }
            if (data.status === 'delete') {
              dyVariable["super_like"] = dyVariable["super_like"] - 1
              dyVariable['comment_super_likes.userId'] = false
            }
            if (commentType === 'reply') {
              setReply(updatePost)
            }
            if (commentType === 'comment') {
              setComment(updatePost)
            }


            // if (data.status === 'delete') {
            //   dyVariable['like'] = dyVariable['like'] + 1
            //   dyVariable['dislike'] = dyVariable['dislike'] - 1
            //   dyVariable['comment_likes.type'] = 'like'
            // }


            // if (data.status === 'create') {
            //   dyVariable[type] = dyVariable[type] + 1
            //   dyVariable['comment_likes.type'] = type
            // } else if (data.status === 'update' && data.type === 'like') {
            //   dyVariable['like'] = dyVariable['like'] + 1
            //   dyVariable['dislike'] = dyVariable['dislike'] - 1
            //   dyVariable['comment_likes.type'] = 'like'
            // }
            // else if (data.status === 'update' && data.type === 'dislike') {
            //   dyVariable['dislike'] = dyVariable['dislike'] + 1
            //   dyVariable['like'] = dyVariable['like'] - 1
            //   dyVariable['comment_likes.type'] = 'dislike'

            // }
            // else {
            //   dyVariable[type] = dyVariable[type] - 1
            //   dyVariable['comment_likes.type'] = ''
            // }
            // if (commentType === 'reply') {
            //   setReply(updatePost)
            // }
            // if (commentType === 'comment') {
            //   setComment(updatePost)
            // }

            let likeId = {}

            if (commentType === 'reply') {
              const key = Object.keys(isBox)
              likeId = { cmtId: replyId, repId: key[0] }
            } else {
              likeId = { cmtId: replyId }
            }
            socket.emit("sendMessage", { postId: item.id, receiverId: dyVariable.userId, type: commentType === 'comment' ? "comment_super_like" : "reply_super_like", userId: user.userId, like: dyVariable['like'], dislike: dyVariable['dislike'], title: item.title, ...likeId, comment: dyVariable.comment })
          }
          if (!data.success && data.status === 'exceeded') {
            Toast(data.message)
            setNotification(true)
          }

        })

    }
  }

  const [notification, setNotification] = useState(false)


  const superLikeHandler = async (item, mainUserId, index) => {
    if (!user.userId) {
      location('/signin')
    }
    else if (user.userId && mainUserId === user.userId) {
      Toast("You can't DIAMOND UPVOTE your own post")
      setNotification(true)
    }
    else {
      const obj = {
        postId: item,
        mainUserId: mainUserId,
      }
      const { data } = await axios.post(url + '/post/like/super', obj)
      if (data.success) {
        let copyPost = [...post]
        if (data.status === 'delete') {
          copyPost[index].super_like -= 1
          copyPost[index]['super_likes.userId'] = false
        }
        if (data.status === 'create') {
          copyPost[index].super_like += 1
          copyPost[index]['super_likes.userId'] = true

          socket.emit("sendMessage", { postId: post[index].id, receiverId: post[index].userId, type: "super_like", userId: user.userId, title: post[index]['title'] })

        }
        setPost(copyPost)
      }
      if (!data.success && data.status === 'exceeded') {
        Toast(data.message)
        setNotification(true)
      }
    }
  }


  const modelHandler = (item) => {
    if (!user.userId) {
      location('/signin')
    } else {
      setUser({ ...user, model: true, modelRetweet: true, retweetObj: item, fired: false })
    }
  }


  const dynamicText = (content, id) => {
    if (content) {
      let dynamicP = content
      let newDyP = content.split('<p>')[1].replace('</p>', '')
      if (newDyP.length > 200) {
        dynamicP = "<p>" + substring(newDyP, 200) + '...' + "</p>"
      } else {
        dynamicP = "<p>" + newDyP + "</p>"
      }
      return dynamicP
    }
  }

  // const retweetHandler = (item, index) => {
  //   if (!user.userId) {
  //     location('/signin')
  //   } else {
  //     setUser({ ...user, retweet: true, retweetObj: item, fired: false })
  //   }
  // }

  // below the useeffect for retweet function
  // useEffect(() => {
  //   if (user.fired && user.retweetObj) {
  //     if (user.retweetObj) {
  //       const copyPost = [...post]
  //       copyPost.filter(item => {
  //         if (item.id === user.retweetObj.id) {
  //           item.retweet += 1
  //         }
  //       })
  //       setPost(copyPost)
  //     }
  //   }
  // }, [user.fired])
  useEffect(() => {
    if (user.newPost) {
      const copyPost = [...post]
      if (user.newPost.retweetId) {
        // console.log(user.newPost.retweetId)
        // console.log(copyPost)
        copyPost.filter(id => {
          if (id.id === user.newPost.retweetId) id.retweet = id.retweet + 1
        })
      }
      copyPost.unshift(user.newPost)
      setPost(copyPost)
    }
  }, [user && user.newPost])
  return (
    <>

      <InfiniteScroll pageStart={0}
        loadMore={findItem}
        hasMore={hasMore}
        // load={<div className="loader" key={0}>Loading ...</div>}
        initialLoad={false}>
        {notification && <Toastify />}
        {
          post.map((item, i) => {
            let tags = []
            if (item.tags) {
              tags = item.tags.split(',')
            }
            let content = {}
            if (item.type === 'Polls' || item.type === 'Answer') {
              content = JSON.parse(item.content)
            }
            let finalDate = ''
            if (item.end_time) {

              let time = new Date(parseInt(item.end_time))
              let hour = time.getHours()
              let zone = 'AM'
              if (time.getHours() > 12) {
                hour = time.getHours() - 12
              }
              if (time.getHours() >= 12) {
                zone = 'PM'
              }
              let minutes = time.getMinutes()
              if (time.getMinutes() === 0) {
                minutes = '00'
              }
              if (time.getMinutes() < 10) {
                minutes = '0' + time.getMinutes()
              }
              finalDate = time.getDate() + '-' + (time.getMonth() + 1) + '-' + time.getFullYear() + ' ' + hour + ':' + minutes + ' ' + zone
            }

            let dynamicP = item.content

            if ((item.type === 'Post' || item.type === 'Retweet') && (item.content && item.content.length > 500 && !postId.includes(item.id))) {
              let newDyP = item.content.split('<p>')[1].replace('</p>', '')
              if (newDyP.length > 500) {
                dynamicP = "<p>" + substring(newDyP, 500) + "</p>"
              } else {
                dynamicP = "<p>" + newDyP + "</p>"
              }
            }

            let topComment;

            if (item.top_comment && JSON.parse(item.top_comment).comment) {
              topComment = JSON.parse(item.top_comment)
            }

            let retweet;
            if (item.retweet_des) {
              retweet = JSON.parse(item.retweet_des)
            }

            return (
              <div className="post" id={i}  >
                <div className="post_all_item">
                  {
                    (admin && item.userId === user.userId) && <div className="edit" ref={editRef}>
                      <div className="edit_icon" onClick={() => postEditMenu(item.id)}>
                        <i className="fa fa-cog"></i>
                      </div>
                      {
                        editmenu === item.id &&
                        <div className="edit_menu">
                          {/* <span onClick={() => postEditHandler(item.id, i, 'edit')} className="btn btn-light">Edit Post</span> */}
                          <span onClick={() => postEditHandler(item.id, i, 'delete')} className="btn btn-light">Delete</span>
                        </div>
                      }
                    </div>
                  }


                  <div className="post_header">
                    <div className="header_left">
                      <div className="img">
                        <Link to={"/profile/" + item.userId}>
                          <img src={item['registers.image'] ? item['registers.image'] : "/profile.png"} alt="" />
                        </Link>
                      </div>
                      <div className="name">
                        <div className="left">
                          <Link to={"/profile/" + item.userId}>
                            <p>{item['registers.username'] ? item['registers.username'] : 'Unknown'}</p>
                            <div className="dot"></div>
                          </Link>
                        </div>
                        <div className="right">
                          <div className="time">
                            <p>{feedTime(item.createdAt, 'ago')}</p>
                          </div>
                        </div>

                      </div>

                    </div>


                    {(((type === 'post' || type === 'Tracking') && !follow) && item.userId !== user.userId) &&
                      <div className="header_right">
                        {(item.tags && !item['trackings.userId'] && !isTracking) && <button onClick={() => sendTrack(item.tags, item.id, i, 'add')} className='button header_right_btn btn_1'>Track</button>
                        }

                        {(item['trackings.userId'] && isTracking) && <button onClick={() => sendTrack(item.tags, item.id, i, 'delete')} className='button header_right_btn btn_1'>Untrack</button>
                        }


                        {(!item.follow && !item['friend_requests.userId']) &&
                          <button onClick={() => sendRequest(item.userId, i)} className='button header_right_btn btn_2'>Follow</button>
                        }
                      </div>
                    }
                  </div>
                  <div className={"post_content_wrapper " + item.type}>

                    {(item.type === 'Polls' || item.type === 'Answer') &&
                      <>
                        <div className="poll_question">
                          <div className="question">
                            {!item.title && <h3>{content.title}</h3>}
                          </div>
                          <div className="answer_1">
                            <input onChange={() => sendPolls(item.id, 'like', i)} name={"answer_" + item.id} id={item.id} type="radio" value={content.choice1} checked={item['post_likes.type'] === 'like' ? "checked" : false} />
                            <label htmlFor={item.id}>{content.choice1}</label>
                          </div>
                          <div className="answer_2">
                            <input onChange={() => sendPolls(item.id, 'dislike', i)} name={"answer_" + item.id} id={(item.id + "__")} type="radio" value={content.choice2} checked={item['post_likes.type'] === 'dislike' ? "checked" : false} />
                            <label htmlFor={(item.id + "__")}>{content.choice2}</label>
                          </div>

                        </div>


                      </>}
                    {
                      item.closed && <>
                        {/* <p>This poll was closed at {finalDate}.</p> */}
                        <div className="poll">
                          <div className="poll_bar">
                            <div className="left" style={{ width: item.like ? parseInt((item.like / (item.like + item.dislike)) * 100) + "%" : 10 + "%", background: "#D9D9D9" }}>
                              <p>{content.choice1}</p>
                            </div>
                            <div className="right" style={{ width: "15%" }}>
                              <p>{item.like ? parseInt((item.like / (item.like + item.dislike)) * 100) : 0}%</p>
                            </div>
                          </div>
                          <div className="poll_bar">
                            <div className="left" style={{ width: item.dislike ? parseInt((item.dislike / (item.like + item.dislike)) * 100) + "%" : 10 + "%", background: "#1877F2" }}>
                              <p>{content.choice2}</p>
                            </div>
                            <div className="right" style={{ width: "15%" }}>
                              <p>{item.dislike ? parseInt((item.dislike / (item.like + item.dislike)) * 100) : 0}%</p>
                            </div>
                          </div>


                          <div className="result">
                            <span>{(item.like + item.dislike)} Votes .</span>
                            <span>Final Results</span>
                          </div>
                        </div>
                      </>
                    }
                    {(item.type === 'Answer' || (item['post_likes.userId'] && item.type === 'Polls')) &&
                      <>
                        <div className="poll">
                          <div className="poll_bar">
                            <div className="left" style={{ width: item.like ? parseInt((item.like / (item.like + item.dislike)) * 100) + "%" : 10 + "%", background: "#D9D9D9" }}>
                              <p>{content.choice1}</p>
                            </div>
                            <div className="right" style={{ width: "15%" }}>
                              <p>{item.like ? parseInt((item.like / (item.like + item.dislike)) * 100) : 0}%</p>
                            </div>
                          </div>
                          <div className="poll_bar">
                            <div className="left" style={{ width: item.dislike ? parseInt((item.dislike / (item.like + item.dislike)) * 100) + "%" : 10 + "%", background: "#1877F2" }}>
                              <p>{content.choice2}</p>
                            </div>
                            <div className="right" style={{ width: "15%" }}>
                              <p>{item.dislike ? parseInt((item.dislike / (item.like + item.dislike)) * 100) : 0}%</p>
                            </div>
                          </div>


                          <div className="result">
                            <span>{(item.like + item.dislike)} Votes .</span>
                            <span>Final Results</span>
                          </div>
                        </div>

                      </>}
                    {(item.type === 'Polls' || item.type === 'Answer') && tags && <div className="post_tag">
                      {tags.map(tag => <Link to={"/tags/" + tag.trim().split(' ').join('-')}><p>{tag}</p></Link>)}
                    </div>
                    }
                    {(item.type === 'Polls' || item.type === 'Answer') && item.end_time &&
                      <p className="mt-2">Close at {finalDate}</p>
                    }



                    {((type !== 'Polls' && (item.type === 'Post'))) && <>
                      <div className="post_content">
                        <div className="post_title">
                          {item.title && <h3>{item.title}</h3>}
                        </div>

                        {item.content &&
                          <div className="post_description mt-2">
                            {dynamicP ? ReactHtmlParser(dynamicP) : ReactHtmlParser(item.content)}
                          </div>

                        }

                        {
                          item.image &&
                          <div className="post_img">
                            {JSON.parse(item.image).map(item => {
                              return (

                                <Link to={url + '/uploads/' + item.filename} target="_blank" rel="noopener noreferrer">
                                  <img src={url + '/uploads/' + item.filename} alt="" />
                                </Link>

                              )
                            })
                            }
                          </div>
                        }
                        {tags && <div className="post_tag">
                          {tags.map(tag => tag && <Link to={"/tags/" + tag.trim().split(' ').join('-')}><p>{tag}</p></Link>)}
                        </div>

                        }

                        {/* {(item.type === 'Retweet') && <a target="_blank" href={"/post/" + item.retweetId}><p className="read_more">Read More <img className="read_more_img" src="/angel.png" alt="" /></p></a>} */}



                        {(item.content && !postId.includes(item.id) && item.type === 'Post' && item.content.length > 500) && <p className="read_more" onClick={() => readMore(item)}>Read More <img className="read_more_img" src="/angel.png" alt="" /></p>}

                      </div>

                      {retweet &&
                        <div className="feed_retweet_wrapper">
                          <div className="retweet_profile">
                            <div className="post_header">
                              <div className="header_left">
                                <div className="img">
                                  <Link to={"/profile/" + item.mainUserId}>
                                    <img src={item.mainUserId === item.userId ? item['registers.image'] ? item['registers.image'] : "/profile.png" : item['registers1.image'] ? item['registers1.image'] : "/profile.png"} alt="" />
                                  </Link>
                                </div>
                                <div className="name">
                                  <div className="left">
                                    <Link to={"/profile/" + item.mainUserId}>
                                      <p>{item.mainUserId === item.userId ? item['registers.username'] ? item['registers.username'] : 'Unknown' : item['registers1.username'] ? item['registers1.username'] : 'Unknown'} </p>
                                      <div className="dot"></div>
                                    </Link>
                                  </div>


                                  <div className="right">
                                    <div className="time">
                                      <p>{feedTime(retweet.createdAt, 'ago')}</p>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="header_right retweet_header_right desktop">
                                <Link target="_blank" to={"/post/" + retweet.id}>View Post</Link>
                              </div>
                            </div>
                          </div>
                          <div className="post_content">
                            {retweet.title &&
                              <div className="post_title">
                                <h3>{retweet.title}</h3>
                              </div>
                            }
                            {(retweet.content) &&
                              <div className="retweet_content">
                                {ReactHtmlParser(dynamicText(retweet.content))}
                              </div>
                            }
                            <div className="header_right retweet_header_right mobile">
                              <Link target="_blank" to={"/post/" + retweet.id}>View Post</Link>
                            </div>

                          </div>
                        </div>
                      }

                      <div className="post_footer">
                        {screen() > 600 &&
                          <div className="footer_left">
                            <div className={item['post_likes.type'] === 'like' ? "like box" : "box"} onClick={() => likeHandler(item.id, 'like', i, item.userId)}>
                              <img src={item['post_likes.type'] === 'like' ? '/like.png' : "/up.png"} alt="" /><span>{item.like}</span>
                            </div>

                            <div className={item['post_likes.type'] === 'dislike' ? "dislike box" : "box"} onClick={() => likeHandler(item.id, 'dislike', i, item.userId)}>
                              <img src={item['post_likes.type'] === 'dislike' ? '/dislike.png' : "/down.png"} alt="" /><span>{item.dislike}</span>
                            </div>


                            <div className={item['super_likes.userId'] ? "like box super_like" : "box super_like"} onClick={() => superLikeHandler(item.id, item.userId, i)}>
                              <img src={item['super_likes.userId'] ? '/icons8-love-19-blue.png' : "/icons8-love-19-white.png"} alt="" /><span>{item.super_like}</span>
                            </div>

                            <div className="box" onClick={() => getComment(item.id)}>
                              <img src="/comment.png" alt="" /><span>{item.comment}</span>
                            </div>

                            <div className="box reteweet" onClick={() => modelHandler(item)}>
                              <img src={"/icons8-retweet-19.png"} alt="" /><span>{item.retweet || 0}</span>
                            </div>
                            {/* 
                            {item.userId !== user.userId &&
                              <div className="box reteweet" onClick={() => retweetHandler(item, i)}>
                                <img src={"/icons8-retweet-19.png"} alt="" /><span>{item.retweet || 0}</span>
                              </div>
                            } */}
                          </div>
                        }

                        {screen() <= 600 &&
                          <div className="footer_left">
                            <div className="row_1">
                              <div className={item['post_likes.type'] === 'like' ? "like box" : "box"} onClick={() => likeHandler(item.id, 'like', i, item.userId)}>
                                <img src={item['post_likes.type'] === 'like' ? '/like.png' : "/up.png"} alt="" /><span>{item.like}</span>
                              </div>

                              <div className={item['post_likes.type'] === 'dislike' ? "dislike box" : "box"} onClick={() => likeHandler(item.id, 'dislike', i, item.userId)}>
                                <img src={item['post_likes.type'] === 'dislike' ? '/dislike.png' : "/down.png"} alt="" /><span>{item.dislike}</span>
                              </div>


                              <div className={item['super_likes.userId'] ? "like box super_like" : "box super_like"} onClick={() => superLikeHandler(item.id, item.userId, i)}>
                                <img src={item['super_likes.userId'] ? '/icons8-love-19-blue.png' : "/icons8-love-19-white.png"} alt="" /><span>{item.super_like}</span>
                              </div>

                            </div>

                            <div className="row_2">
                              <div className="box" onClick={() => getComment(item.id)}>
                                <img src="/comment.png" alt="" /><span>{item.comment}</span>
                              </div>

                              {item.userId !== user.userId &&
                                <div className="box reteweet" onClick={() => modelHandler(item)}>
                                  <img src={"/icons8-retweet-19.png"} alt="" /><span>{item.retweet || 0}</span>
                                </div>
                              }
                              <div className="footer_right">
                                <button className="footer_btn" onClick={() => boxHandler(item.id, 'comment', {})}>Reply</button>
                              </div>
                            </div>
                          </div>
                        }

                        {screen() > 600 &&
                          <div className="footer_right">
                            <button className="footer_btn" onClick={() => boxHandler(item.id, 'comment', {})}>Reply</button>
                          </div>
                        }
                      </div>

                      {/* Top comment */}
                      {(topComment && !comment[item.id]) &&
                        <div className="top_comment">
                          <div className="post_comments">
                            <div className="user">
                              <Link to={"/profile/" + topComment.userId}>
                                <div className="img">
                                  <img src={topComment['registers.userImg'] ? topComment['registers.userImg'] : '/profile.png'} alt="" />
                                  <div className="line" style={{
                                    height: height
                                  }}></div>
                                </div>
                              </Link>
                              <div className="name">
                                <div className="user_data">
                                  <Link to={"/profile/" + topComment.userId}> <span className="name">{topComment['registers.username']} · </span> </Link>
                                  <span className="time">{feedTime(topComment.createdAt, 'ago')}</span>
                                </div>

                                <div className="user_comment">
                                  {topComment.comment && ReactHtmlParser(topComment.comment)}
                                  {topComment.image && <img className="comment_img" src={topComment.image} alt="" />}
                                </div>

                                <div className="comment_like">
                                  <div onClick={() => getComment(item.id)} style={{ cursor: 'auto' }} className={topComment['comment_likes.type'] === 'like' ? "like box" : "box"}>
                                    <img src={topComment['comment_likes.type'] === 'like' ? "/like.png" : "/up.png"} alt="" /><span>{topComment.like}</span>
                                  </div>

                                  <div onClick={() => getComment(item.id)} style={{ cursor: 'auto' }} className={topComment['comment_likes.type'] === 'dislike' ? "dislike box" : "box"}>
                                    <img src={topComment['comment_likes.type'] === 'dislike' ? "/dislike.png" : "/down.png"} alt="" /><span>{topComment.dislike}</span>
                                  </div>

                                  <div onClick={() => getComment(item.id)} style={{ cursor: 'auto' }} className={topComment['comment_likes.type'] === "super" ? "like box super_like" : "box super_like"}>
                                    <img src={topComment['comment_likes.type'] === "super" ? '/icons8-love-16-blue.png' : "/icons8-love-16-white.png"} alt="" /><span>{topComment.super_like || 0}</span>
                                  </div>



                                </div>
                                <div className="view_comment">
                                  <button className="btn" onClick={() => getComment(item.id)}>View All Comments</button>
                                </div>
                                {/* {
                                (topComment.totalReply > 0) &&
                                <div className="more_comment mt-2 cursor">
                                  <p onClick={() => boxHandler(item.id, 'reply', cmt.id)} className="reply_btn btn">{cmt.totalReply} replies</p>
                                </div>
                              } */}

                              </div>
                            </div>
                          </div>
                        </div>
                      }

                      {/*  comment Box to write */}
                      {
                        ((type === 'comment') || (isBox[item.id] && isBox[item.id] === 'comment' || isBox[item.id] === 'reply')) && <>
                          <div className="post_comment_form">
                            <textarea rows={textarearow} onChange={(e) => commentHandler(e, 'comment')} placeholder="Write your comment" value={commentText['comment']} ></textarea>

                            <i onClick={() => imageUpload(item.id)} class="fa fa-image img_box"></i>
                            <input className="hidden" onChange={(e) => cmtImgChange(e, 'comment')} key={cmtImg['comment']} type="file" ref={(el) => (imgRef.current[item.id] = el)} />
                            {cmtImg['comment'] &&
                              <div className="img_prev">
                                <img src={URL.createObjectURL(cmtImg['comment'])} alt="" />
                                <i onClick={cmtImgClose} className="img_change fa fa-close"></i>
                              </div>
                            }
                            <img className="cmt_btn_img" src="/areoplane.png" alt="" onClick={() => postComment(item, 'comment',)} />

                          </div>
                          <div className="expand text-right">
                            <i className={expand ? "fa fa-angle-down" : "fa fa-angle-up"}></i> <span onClick={textAreaHandler}>Expand</span>
                          </div>
                        </>}

                      {/*  First comment */}
                      {comment[item.id] && comment[item.id].rows.map((cmt, cmtId) => {
                        return (
                          <div className="post_comments" id={"comment_id_" + cmt.id}>
                            <div className="user">
                              <Link to={"/profile/" + cmt.userId}>
                                <div className="img">
                                  <img src={cmt['registers.userImg'] ? cmt['registers.userImg'] : '/profile.png'} alt="" />
                                  <div className="line" style={{
                                    height: height
                                  }}></div>
                                </div>
                              </Link>

                              <div className="name">

                                <div className="user_data">
                                  <Link to={"/profile/" + cmt.userId}> <span className="name">{cmt['registers.username']} · </span> </Link>
                                  <span className="time">{feedTime(cmt.createdAt, 'ago')}</span>
                                </div>

                                <div className="user_comment">
                                  {cmt.comment && ReactHtmlParser(cmt.comment)}
                                  {cmt.image && <img className="comment_img" src={cmt.image} alt="" />}

                                </div>
                                <div className="comment_like">
                                  <div className={cmt['comment_likes.type'] === 'like' ? "like box" : "box"} onClick={() => cmtLikeHandler(item.id, 'like', cmtId, 'comment', cmt.id, item, cmt.userId)}>
                                    <img src={cmt['comment_likes.type'] === 'like' ? "/like.png" : "/up.png"} alt="" /><span>{cmt.like}</span>
                                  </div>
                                  <div className={cmt['comment_likes.type'] === 'dislike' ? "dislike box" : "box"} onClick={() => cmtLikeHandler(item.id, 'dislike', cmtId, 'comment', cmt.id, item, cmt.userId)}>
                                    <img src={cmt['comment_likes.type'] === 'dislike' ? "/dislike.png" : "/down.png"} alt="" /><span>{cmt.dislike}</span>
                                  </div>

                                  <div className={cmt['comment_super_likes.userId'] ? "like box cmt_super_like" : "box cmt_super_like"} onClick={() => cmtSuperLikeHandler(item.id, 'dislike', cmtId, 'comment', cmt.id, item, cmt.userId)}>
                                    <img src={cmt['comment_super_likes.userId'] ? "/icons8-love-16-blue.png" : "/icons8-love-16-white.png"} alt="" /><span>{cmt.super_like}</span>
                                  </div>

                                  <div className="box reply_icon" onClick={() => boxHandler(item.id, 'reply', cmt)}>
                                    <img src="/comment.png" alt="" /><span>Reply</span>
                                  </div>

                                </div>
                                {
                                  (cmt.totalReply > 0) &&
                                  <div className="more_comment mt-2 cursor">
                                    <p onClick={() => boxHandler(item.id, 'reply', cmt)} className="reply_btn btn">{cmt.totalReply} replies</p>
                                  </div>
                                }
                                {/* Reply comment Box */}
                                {isBox[cmt.id] && isBox[cmt.id] === 'reply' && <>
                                  <div className="post_comment_form">
                                    <textarea onChange={(e) => commentHandler(e, 'reply')} placeholder="Write your comment" rows={textarearow} value={commentText['reply']} ></textarea>

                                    <i onClick={() => imageUpload(cmt.id * 100)} class="fa fa-image img_box"></i>
                                    <input className="hidden" onChange={(e) => cmtImgChange(e, 'reply')} key={cmtImg['reply']} type="file" ref={(el) => (imgRef.current[cmt.id * 100] = el)} />
                                    {cmtImg['reply'] &&
                                      <div className="img_prev">
                                        <img src={URL.createObjectURL(cmtImg['reply'])} alt="" />
                                        <i onClick={cmtImgClose} className="img_change fa fa-close"></i>
                                      </div>
                                    }
                                    <img className="cmt_btn_img" src="/areoplane.png" alt="" onClick={() => postComment(item, 'reply', cmt)} />
                                  </div>
                                  <div className="expand text-right">
                                    <i className={expand ? "fa fa-angle-down" : "fa fa-angle-up"}></i> <span onClick={textAreaHandler}>Expand</span>
                                  </div>
                                </>
                                }

                                {/* Comment reply */}
                                {
                                  reply[cmt.id] && reply[cmt.id].rows.map((rep, repId) => {
                                    return (
                                      <div className="reply user" id={"reply_id_" + rep.id}>
                                        <Link to={"/profile/" + rep.userId}>
                                          <div className="img">
                                            <img src={rep['registers.userImg'] ? rep['registers.userImg'] : '/profile.png'} alt="" />
                                          </div>
                                        </Link>
                                        <div className="name">
                                          <div className="user_data">
                                            <Link to={"/profile/" + rep.userId}>
                                              <span className="name">{rep['registers.username']} · </span></Link>
                                            <span className="time">{feedTime(rep.createdAt, 'ago')}</span>
                                          </div>
                                          <div className="user_comment">

                                            {rep.comment && ReactHtmlParser(rep.comment)}
                                            {rep.image && <img className="comment_img" src={rep.image} alt="" />}

                                          </div>
                                          <div className="comment_like">
                                            <div className={rep['comment_likes.type'] === 'like' ? "like box" : "box"} onClick={() => cmtLikeHandler(cmt.id, 'like', repId, 'reply', rep.id, item, rep.userId)}>
                                              <img src={rep['comment_likes.type'] === 'like' ? "/like.png" : "/up.png"} alt="" /><span>{rep.like}</span>
                                            </div>
                                            <div className={rep['comment_likes.type'] === 'dislike' ? "dislike box" : "box"} onClick={() => cmtLikeHandler(cmt.id, 'dislike', repId, 'reply', rep.id, item, rep.userId)}>
                                              <img src={rep['comment_likes.type'] === 'dislike' ? "/dislike.png" : "/down.png"} alt="" /><span>{rep.dislike}</span>
                                            </div>

                                            <div className={rep['comment_super_likes.userId'] ? "like box cmt_super_like" : "box cmt_super_like"} onClick={() => cmtSuperLikeHandler(cmt.id, 'dislike', repId, 'reply', rep.id, item, rep.userId)}>
                                              <img src={rep['comment_super_likes.userId'] ? "/icons8-love-16-blue.png" : "/icons8-love-16-white.png"} alt="" /><span>{rep.super_like}</span>
                                            </div>

                                            {/* <div className="box">
                                        <img src="comment.png" alt="" /><span>Reply</span>
                                      </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })

                                }

                              </div>

                            </div>
                          </div >
                        )
                      })}

                      {/* {(item.type === 'Polls') &&
                    <>
                      <div className="poll">
                        <div className="poll_bar">
                          <div className="left" style={{ width: 80 + "%", background: "#D9D9D9" }}>
                            <p>Bitcoin</p>
                          </div>
                          <div className="right" style={{ width: "0%" }}>
                            <p>75%</p>
                          </div>
                        </div>
                        <div className="poll_bar">
                          <div className="left" style={{ width: 80 - 20 + "%", background: "#1877F2" }}>
                            <p>ETH</p>
                          </div>
                          <div className="right" style={{ width: "0%" }}>
                            <p>25%</p>
                          </div>
                        </div>
                        <div className="result">
                          <span>1082 Votes .</span>
                          <span>Final Results</span>
                        </div>
                      </div>
                    </>} */}

                    </>}

                  </div >
                </div>
              </div>
            )
          })
        }
      </InfiniteScroll>

    </>
  )

}

export default Post;