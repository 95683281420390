
import { useState, useRef, useEffect, useContext, useMemo, useCallback } from 'react'
import axios from 'axios'
import DateTimePicker from 'react-datetime';
import { url } from '../../helpers/url'
import { Context } from '../../helpers/context'
import { Link } from 'react-router-dom';
function Model({ data, sendPost, edit, type }) {
  const ref = useRef(null)
  const [user, setUser] = useContext(Context)
  const [model, setModel] = useState('block')
  const [select, setSelect] = useState('Post')

  const modelHandler = (e) => {
    if (ref && !ref.current.contains(e.target)) {
      setUser({ ...user, read_more: false, loginPopup: false })
    }
  }
  const modelCloseHandler = (e) => {
    setUser({ ...user, read_more: false, loginPopup: false })
  }


  return (
    <div className="model" onClick={modelHandler} >
      <div className="form_main login_popup">
        <div className="form" ref={ref}>
          <div className="form_header">
            <i className="fa fa-close" onClick={modelCloseHandler}></i>
          </div>

          <div className='text-center'>This is a members-only post. Please <Link to="/signin">login </Link>to view the post.</div>
        </div>
      </div>
    </div>
  );
}

export default Model;