import { useState, useEffect } from 'react'
import Layout from '../../../admin-component/layout'
import { url } from '../../../helpers/url'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

function EditUser({ mode }) {
  const { id } = useParams()
  const [single, setSingle] = useState({ rank: '' })
  const [load, setLoad] = useState(false)
  const [point, setPoint] = useState(0)
  const [pointLoad, setPointLoad] = useState(false)
  useEffect(() => {
    if (id) {
      axios.get(`${url}/user/point?id=${id}`).then(({ data }) => {
        if (data.success) {
          setPoint(data.point)
          setPointLoad(true)
        }
      })
      axios.get(`${url}/admin/user/single?id=${id}`).then(({ data }) => {
        if (data.success && data.data) {
          setSingle(data.data)
          setLoad(true)
        }
      })
    }
  }, [id])


  const [rank, setRank] = useState('')
  const onType = (e) => {
    setSingle({ ...single, rank: e.target.value })
  }

  const [success, setSuccess] = useState(false)

  const userRankHandler = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("rank", single.rank)
    data.append("id", single.id)
    axios.post(`${url}/admin/user/single`, data).then(({ data }) => {
      if (data.success) {
        setSuccess(true)
      }
    })
  }

  return (
    <Layout>
      <div className="admin_form">
        <h3 className='heading mb-2'>Update User Ranking</h3>

        {(success) &&
          <div className="alert alert-success mt-3">User rank has been updated</div>
        }
        {(!success && load) &&
          <form onSubmit={userRankHandler}>
            <div className="input_items">

              <div className="input_group mt-3">
                <label className='text-success' htmlFor="">Current point {point} </label>
              </div>

              {/* <div className="input_group mt-3">
                <label htmlFor="">First Name</label>
                <input type="text" name="" disabled value={data.username} id="" />
              </div>

              <div className="input_group mt-3">
                <label htmlFor="">Last Name</label>
                <input type="text" name="" disabled value={data.fname} id="" />
              </div>


              <div className="input_group mt-3">
                <label htmlFor="">Username</label>
                <input type="text" name="" disabled value={data.lname} id="" />
              </div> */}


              <div className="input_group mt-3">
                <label htmlFor="">Rank Text</label>
                <textarea onChange={onType} name="rank" id="rank" value={single.rank} ></textarea>
              </div>

              <div className="input_group">
                <button className='admin_btn' type='submit'><span className='capitalize'>Update</span> </button>
              </div>
            </div>
          </form>
        }
      </div>
    </Layout>
  );
}

export default EditUser;