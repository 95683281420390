import { useState, useEffect } from 'react'
import axios from 'axios';

import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { url } from '../../helpers/url';



function Singup({ type }) {

  const [singup, setSingup] = useState({})
  const [status, setStatus] = useState(false)
  const [error, setError] = useState(false)
  const [exist, setExist] = useState(false)
  const [username, setUsername] = useState(false)
  const location = useNavigate()
  let query = useLocation()
  if (query) {
    query = query.search.split('=')[1]
  }

  const onType = (e) => {
    setSingup({ ...singup, [e.target.name]: e.target.value })
  }
  const [logError, setLogError] = useState(false)


  const [reset, setReset] = useState(false)
  const [forget, setForget] = useState(0)
  const [expire, setExpire] = useState(false)
  const [verify, setVerify] = useState(false)
  const [deactivated, setDeactivated] = useState(false)

  const postSingup = (e) => {
    e.preventDefault()
    if (type === 'singin') {
      axios.post(url + '/singin/', singup)
        .then(({ data }) => {

          if (data.success && data.token) {
            localStorage.setItem("jay.com", data.token)
            if (data.type === 'customer') {
              window.location.href = '/'
            }
            if (data.type === 'admin') {
              window.location.href = '/admin'
            }
          } else if (data.success && data.verify === 'required') {
            setVerify(true)
          }
          else if (data.success && data.verify === 'disabled') {
            setDeactivated(true)
          }

          else {
            setLogError(true)
          }
        })
    } else if (type === 'singup') {
      if (singup.password === singup.password2) {
        axios.post(url + '/singup/', singup)
          .then(({ data }) => {
            if (data.success && !data.exist && !data.username) {
              setStatus(true)
              setExist(false)
              setError(false)
              setUsername(false)
            } else if (data.exist || data.username) {
              setExist(data.exist)
              setUsername(data.username)
            }
            else {
              setError(true)
            }
          })
      }
    }
    else if (type === 'forget') {
      if (singup.email) {
        axios.post(url + '/forget/', { email: singup.email })
          .then(({ data }) => {
            if (data.success) {
              setForget(2)
            } else {
              setForget(1)
            }
          })
      }
    }
    else if (query && type === 'reset' && singup.password === singup.password2) {
      axios.post(url + '/reset/', { token: query, password: singup.password })
        .then(({ data }) => {
          if (data.reset) {
            location('/signin')
          }
          if (data.expire) {
            setExpire(true)
          }
        })
    }
  }


  return (
    <div className={"singup row " + type}>

      {type === 'singup' &&
        <div className="col col_left">
          <div className="col_left_box">
            <div className="singup_header">
              <div className="left">
                <h2>Daily Market</h2>
                <h2>EXCHANGE</h2>
              </div>
              <div className="right">
                <img src="singup.png" alt="" />
              </div>
            </div>
            <div className="singup_footer">
              <h3>Become a Full Access Member</h3>
              <h3>$49/ <span>month</span></h3>

              <div className="singup_description">
                <span></span>  <p>Be part of a global network of elite investors</p>
              </div>
              <div className="singup_description">
                <span></span>  <p>Access our market insights and trade ideas</p>
              </div>
              <div className="singup_description">
                <span></span>  <p>See what our members are holding in their portfolios</p>
              </div>
              <div className="singup_description">
                <span></span><p>Learn from the best, observe how they navigate market volatility</p>
              </div>
              <div className="singup_button">
                <div className="left">
                  <p>Sign Up with two friends</p>
                  <p>Group Discount!</p>
                </div>
                <div className="right">
                  <p>$39/<span>month per person</span></p>
                </div>


              </div>
              <div className="img">
                <img src="singup_bg2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      }
      <div className="col col_right">
        {type === 'singup' &&
          <div className="col_right_box_header">
            <a href="/"> <img src="left.png" alt="" /><span>Back</span></a>
          </div>
        }
        <div className="col_right_box">
          {type === 'singup' && <>
            <h2>Sign Up</h2></>
          }
          {type === 'singin' && <>
            <p className="noaccount">Don’t have an account? <a href="/singup">Sign Up</a></p></>
          }

          <form action="" onSubmit={postSingup}>
            <div className="singup_form">
              {error && <div className="alert alert-danger">
                Something is wrong.
              </div>}
              {exist && <div className="alert alert-danger">
                We're sorry. This login email already exists. Please try a different email address to register, or login to your existing account.
              </div>}
              {username && <div className="alert alert-danger">
                Sorry, that username already exists.
              </div>}
              {status &&
                <div className="alert alert-success">
                  Your account has been created successfully! A verification link has been sent to your email, please check your inbox or spam folder and click on the verification link to activate your account. Welcome to the Daily Market Exchange!
                </div>
              }
              {!status && <>


                {type === 'singup' && <>
                  {/* <div className="form_item">
                    <label htmlFor="">Full Name</label>
                    <input onChange={onType} required type="text" name="name" placeholder="Enter Full Name" id="" />
                  </div> */}
                  <div className="form_item">
                    <label htmlFor="">Username</label>
                    <input onChange={onType} required type="text" name="username" placeholder="Enter Username" id="" />
                  </div>
                </>}

                {(type === 'singin' || type === 'singup') && <>
                  <div className="form_item">
                    <label htmlFor="">Email Address</label>
                    <input onChange={onType} required type="text" name="email" placeholder="Enter Email" id="" />
                  </div>

                  <div className="form_item">
                    <label htmlFor="">Password</label>
                    <input onChange={onType} required type="password" name="password" placeholder="Enter Password" id="" />
                  </div>
                </>}
                {type === 'singup' && <>
                  <div className="form_item">
                    <label htmlFor="">Confirm Password</label>
                    <input onChange={onType} required type="password" name="password2" placeholder="Enter Confirm Password" id="" />
                  </div>
                </>}
                {(type === 'singup' && singup.password2 && singup.password && singup.password2.length > 0 && singup.password.length > 0 && singup.password !== singup.password2) && <div className="alert alert-danger">
                  Password and Confirm Password do not match
                </div>}

                {(type === 'singin' && logError) &&
                  <div className="alert alert-danger">
                    Incorrect password or email address
                  </div>
                }
                {(type === 'singin' && deactivated) &&
                  <div className="alert alert-danger">
                    Your account has been suspended. Please contact us at admin@dailymarketexchange.com for further information.
                  </div>
                }
                {(type === 'singin' && verify) &&
                  <div className="alert alert-danger">
                    Please verify your email address. Check your inbox or spam folder for our verification link.
                  </div>
                }

                {type == 'singup' && <>


                  <div className="form_item">
                    <button>Create Account</button>
                  </div>
                  <div className="form_item singin">
                    <p>Already have an account? <a href='/signin'>Sign In</a></p>
                  </div>
                </>}
                {type === 'singin' && <>
                  <div className="form_item">
                    <button>Login</button>
                  </div>
                  <div className="forget_password">
                    <Link to={'/forget'}>Forgot Password</Link>
                  </div>
                </>}

                {type === 'forget' && <>
                  {forget !== 2 &&
                    <div className="form_item">
                      <label htmlFor="">Email Address</label>
                      <input onChange={onType} required type="text" name="email" placeholder="Enter Email" id="" />
                    </div>
                  }
                  {forget === 2 &&
                    <div className="alert alert-success">
                      The link to reset your password has been sent to your email
                    </div>
                  }
                  {forget === 1 &&
                    <div className="alert alert-danger">
                      This email is not found
                    </div>
                  }
                  {forget !== 2 &&
                    <div className="form_item">
                      <button>Reset Password</button>
                    </div>
                  }

                </>}

                {(expire && type === 'reset') &&
                  <div className="alert alert-danger">
                    Link is expired
                  </div>
                }

                {(!expire && type === 'reset' && query) && <>
                  <div className="form_item">
                    <label htmlFor="">Password</label>
                    <input onChange={onType} required type="password" name="password" placeholder="Enter Password" id="" />
                  </div>
                  <div className="form_item">
                    <label htmlFor="">Confirm Password</label>
                    <input onChange={onType} required type="password" name="password2" placeholder="Enter Confirm Password" id="" />
                  </div>


                  {(type === 'reset' && singup.password2 && singup.password && singup.password2.length > 0 && singup.password.length > 0 && singup.password !== singup.password2) && <div className="alert alert-danger">
                    Confirm password did not match
                  </div>}
                  <div className="form_item">
                    <button>Update Password</button>
                  </div>

                </>}

                {/* <div className="form_item">
                <div className="form_card">
                  <div className="left">
                    <label htmlFor="">Payment Information</label>
                  </div>
                  <div className="right">
                    <img src="paypal.png" alt="" />
                    <img src="sped.png" alt="" />
                    <img src="master.png" alt="" />
                    <img src="visa.png" alt="" />
                    <img src="america.png" alt="" />
                  </div>
                </div>

                <input type="password" name="" placeholder="Enter Password" id="" /> 
            </div>*/}
              </>
              }
            </div>
          </form>

          {/* Below is stripe payment form  */}
          {/* 
          {type !== 'singin' &&
            <div className="stripe_items">
              <p>Payment Information</p>
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>

            </div>} */}
        </div>
      </div >
    </div >


  );
}

export default Singup;