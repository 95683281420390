import Layout from '../../admin-component/layout'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { url } from "../../helpers/url";
import Pagination from '../../admin-component/pagination';
function ViewUsers() {
  const [row, setRow] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [count, setCount] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [load, setLoad] = useState(false)
  useEffect(() => {
    axios.get(`${url}/admin/comment?page=0&mode=view`).then(({ data }) => {
      if (data.success) {
        setRow(data.rows)
        setOldRow(data.rows)
        setCount(data.count)
        setOldCount(data.count)
        setLoad(true);
      }
    })
  }, [])

  const postHandler = (id) => {
    axios.post(`${url}/admin/comment/`, { mode: 'delete', id: id }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id !== id)
        setRow(filter)
      }
    })
  }
  function getPaginationId(id) {
    setLoad(false)
    const page = id > 0 ? id * 20 : 0
    axios.get(`${url}/admin/comment?page=${page}&mode=view`)
      .then(({ data }) => {
        setRow(data.rows);
        setOldRow(data.rows)
        setCount(data.count);
        setOldCount(data.count);
        setLoad(true);
      })
  }


  const chnageHandler = (e, id, arrayId) => {
    const status = e.target.value
    axios.post(`${url}/admin/comment/`, { mode: 'edit', id: id, status: status }).then(({ data }) => {
      if (data.success) {
        const filter = row.filter(item => item.id === id)
        let update = [...row]
        update[arrayId].status = status
        setRow(update)
      }
    })
  }

  const [timer, setTimer] = useState(null);

  const changeDelay = (change) => {

    if (change.length) {

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          axios.get(`${url}/admin/search?search=${change}&type=comment`)
            .then(({ data }) => {
              setRow(data.rows)
              setCount(data.count);
            });
        }, 1000)
      );
    }
    if (change.length === 0) {
      setRow(oldRow)
      setCount(oldCount)
    }
  }



  return (
    <Layout>

      <div className="admin_table">
        <div className="admin_body_header">
          <div className="left_header">
            <h3>Comment List</h3>
          </div>
          <div className="right_header">
            <input onChange={(e) => changeDelay(e.target.value)} type="text" name="" id="" placeholder='Search comment' />
          </div>

        </div>

        {
          load && row.length === 0 && <div className="alert alert-danger">
            No comment is found
          </div>
        }

        <table class="table table-hover table-striped left_table">
          <thead>
            <tr>
              <th>Comment</th>
              <th>Image</th>
              <th>View User</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {row.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{item.comment}</td>
                  <td>{item.image && <Link to={item.image}><img className='comment_admin_img' src={item.image} alt="" /> </Link>}</td>
                  <td><Link className='btn btn-warning' target={"_blank"} to={"/profile/" + item.userId}>View User</Link></td>
                  <td className='cursor' onClick={() => postHandler(item.id)}><p className='btn btn-dark'>Delete</p></td>
                </tr>
              )
            })}

            {count.length > 0 && <Pagination row={row.length} count={count} clickId={getPaginationId} />}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewUsers;