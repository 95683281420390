export const feedTime = (before, content = "") => {
  if (before) {
    const past = new Date(before)
    const today = new Date()
    const time = today - past
    const minutes = 1000 * 59
    const hour = 1000 * 60 * 60
    const day = 1000 * 60 * 60 * 24
    if (time < minutes) {
      return parseInt(time / 1000) + " sec " + content
    } else if (time > minutes && time < hour) {
      return parseInt(time / 60000) + " min " + content
    } else if (time > minutes && time < day) {
      return parseInt(time / hour) + " hours " + content
    } else {
      return past.getMonth() + 1 + "-" + past.getDate() + "-" + past.getFullYear()
    }
  }
}

export const todayIs = (curDate = null) => {
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const date = curDate ? new Date(curDate) : new Date()
  const monthVerify = month[date.getMonth()] > 9 ? month[date.getMonth()] : "0" + month[date.getMonth()]
  const dateVerify = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
  const todayIs = date.getFullYear() + "-" + monthVerify + "-" + dateVerify
  return todayIs
}

export const todayAsia = (curDate = null) => {
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const date = curDate ? new Date(curDate) : new Date()
  const monthVerify = month[date.getMonth()] > 9 ? month[date.getMonth()] : "0" + month[date.getMonth()]
  const dateVerify = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
  const todayIs = dateVerify + "-" + monthVerify + "-" + date.getFullYear()
  return todayIs
}

export const todayUs = (date) => {
  const todayUs = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  })
  const today = new Date(todayUs)
  // console.log(today)
  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const monthVerify = month[today.getMonth()] > 9 ? month[today.getMonth()] : "0" + month[today.getMonth()]
  const dateVerify = today.getDate() > 9 ? today.getDate() : "0" + today.getDate()
  const todayIs = today.getFullYear() + "-" + monthVerify + "-" + dateVerify
  return todayIs
}

export const substring = (text, total) => {
  if (text) {
    return text.substring(0, total)
  }
}

export const screen = () => {
  let windowScreen
  if (typeof window !== "undefined") {
    windowScreen = window.screen.width
  }
  return windowScreen
}