import Header from '../../helpers/header/header'

import Feed from '../../components/feed/feed'
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { url } from '../../helpers/url';
import axios from 'axios';
import Model from '../../components/model/model'
function Recent() {
  const [load, setLoad] = useState(false)
  const router = useLocation()


  const [post, setPost] = useState([])

  useEffect(() => {
    axios.get(url + `/post?offset=0&recent=true`)
      .then(({ data }) => {
        if (data.success) {
          setPost(data.rows)
        }
        setLoad(true)
      })
  }, [])

  const [open, setOpen] = useState('none')

  const [edit, setEdit] = useState({})
  const editData = (data) => {
    setEdit(data)
    setOpen('block')
  }
  const getModel = (data) => {
    if (data === 'none') {
      setEdit({})
      setOpen('none')
    }
  }
  const getPost = (data) => {

  }

  return (
    <>
      <Header>
        <div className="col-lg-9 body_col">
          {(load && post.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && post.length > 0) && <>
            <Feed type="post" admin={true} data={post} edit={editData} />
          </>
          }
        </div>
      </Header>
      {open === 'block' && edit.id && <Model style={open} data={getModel} edit={edit} sendPost={getPost} />}
    </>
  );
}

export default Recent;