import { ChartDonut, ChartThemeColor } from '@patternfly/react-charts';
import Header from '../../helpers/header/header';
import Feed from '../../components/feed/feed'
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { url } from '../../helpers/url';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser"
import { feedTime, substring } from "../../helpers/functions";
import { Context } from '../../helpers/context';

const notLoginText = (<p>Please <a href="/signin">login</a> to view this information </p>)


export default function Profile() {
  const [user, setUser] = useContext(Context)
  const [menu, setMenu] = useState('Post')
  // const [menu, setMenu] = useState('Post')
  const [profile, setProfile] = useState({})
  const profileMenu = (item) => {
    setMenu(item)
    return item
  }

  const [post, setPost] = useState([])
  const [oldPost, setOldPost] = useState([])
  const [load, setLoad] = useState(false)
  const router = useParams()
  const location = useNavigate()
  const [height, setHeight] = useState('80%')
  useEffect(() => {
    if (document.querySelector('.user')) {
      const height = document.querySelector('.user').clientHeight
      setHeight(height - 50)
    }

  }, [])

  const [follow, setFollow] = useState(false)

  const [point, setPoint] = useState(0)
  const [pointLoad, setPointLoad] = useState(false)
  useEffect(() => {
    if (router.id) {
      axios.get(url + `/profile?offset=0&profile=${router.id}&type=Post&follow=${router.id}`)
        .then(({ data }) => {
          if (data.success && data.profile) {
            setProfile(data.profile)
            setPost(data.rows)
            setOldPost(data.rows)
          }
          if (data.success && data.isFollowing) {
            setFollow(true)
          }
          setLoad(true)
        })

      axios.get(url + `/user/point?id=${router.id}`)
        .then(({ data }) => {
          if (data.success) {
            setPoint(data.point)
            setPointLoad(true)
          }
          setLoad(true)
        })
    }
  }, [router.id])

  const [cmtPost, setCmtPost] = useState([])
  const [portfolio, setPortfolio] = useState([])
  const [portfolioObj, setPortfolioObj] = useState({})
  const [totalValue, setTotalValue] = useState(0)
  const [loadPort, setLoadPort] = useState(false)
  const [following, setFollowing] = useState([])
  const [tracking, setTracking] = useState([])

  useEffect(() => {
    if (user.userId) {
      if (menu === 'Post') {
        setPost(oldPost)
      }
      if (menu === 'Comment' && router.id) {
        axios.get(url + '/activity?offset=0&userId=' + router.id)
          .then(({ data }) => {
            if (data.success) {
              setCmtPost(data.rows)
            }

          })
      }
      if (menu === 'Polls' && router.id) {
        axios.get(url + `/profile?offset=0&profile=${router.id}&type=Polls`)
          .then(({ data }) => {
            if (data.success && data.profile) {
              setProfile(data.profile)
              setPost(data.rows)
            }
            setLoad(true)
          })
      }

      if (menu === 'Portfolio' && router.id) {
        axios.get(url + `/portfolio?userId=${router.id}`)
          .then(({ data }) => {
            if (data.success && data.portfolio && data.portfolio.portfolio) {
              const parse = JSON.parse(data.portfolio.portfolio)
              const keys = Object.keys(parse)
              setPortfolioObj(parse)
              setPortfolio(keys)
              let total = 0
              keys.map(item => total += parseInt(parse[item].value))
              setTotalValue(total)
            }
            setLoadPort(true)
          })
      }


      if (menu === 'Following') {
        axios.get(url + `/following?mode=view&page=0`)
          .then(({ data }) => {
            if (data.success) {
              setFollowing(data.rows)
            }
          })
      }
      if (menu === 'Tracking') {
        axios.get(url + `/track?mode=view&page=0`)
          .then(({ data }) => {
            if (data.success) {
              setPost(data.rows)
            }
          })
      }
    } else {
      setLoadPort(true)
    }
  }, [menu, router.id])

  const [postId, setPostId] = useState([])
  const readMore = (id) => {
    setPostId([...postId, id])
  }

  const sendRequest = () => {
    if (!user.userId) {
      location('/signin')
    } else {


      if (follow) {
        axios.post(url + '/follow?type=unfollow', { receiverId: router.id })
          .then(({ data }) => {
            if (data.success) {
              setFollow(false)
            }
          })
      } else {
        axios.post(url + '/follow?type=follow', { receiverId: router.id })
          .then(({ data }) => {
            if (data.success) {
              setFollow(true)
            }
          })
      }
    }
  }

  const [unfollow, setUnfollow] = useState({})
  const unFollowHandler = (followId) => {
    if (!user.userId) {
      location('/signin')
    } else {
      if (unfollow[followId]) {
        axios.post(url + '/follow?type=unfollow', { receiverId: followId })
          .then(({ data }) => {
            if (data.success) {
              setUnfollow({ ...unfollow, [followId]: false })
            }
          })
      } else {
        axios.post(url + '/follow?type=follow', { receiverId: followId })
          .then(({ data }) => {
            if (data.success) {
              setUnfollow({ ...unfollow, [followId]: true })
            }
          })
      }
    }
  }


  const unTrackHandler = (post) => {
    setPost(post)
  }
  return (
    <>
      <Header>

        {(load && profile.id) &&
          <div className="col-lg-9 profile-row body_col">
            <div className="profile_bg" style={{
              backgroundImage: `url('/profile_bg.png')`
            }}></div>
            <div className="profile_items_wrapper">
              <div className="profile_top_items">
                <div className="left">
                  <img src={profile.image ? profile.image : "/user_profile.png"} alt="" />
                </div>
                <div className="right">
                  {profile.id === user.userId && <Link to={'/profile/'}>Edit Profile</Link>}
                  {profile.id !== user.userId && <p onClick={sendRequest}>{follow ? 'Following' : 'Follow'}</p>}

                </div>
              </div>
              <div className="profile_bottom_items">
                <div className="left">
                  <h1>{profile.username ? profile.username : 'Unknown'}</h1>
                  <div className="follower">
                    <p><span>{profile.following}</span> Following</p>
                    <p><span>{profile.follower}</span> Followers</p>
                    {pointLoad && <p className='point'><span>{point}</span> {point > 0 ? 'Points' : 'Point'}</p>}
                  </div>
                  <div className="profile_menu">
                    <p onClick={() => profileMenu('Post')} className={menu === 'Post' && 'active'}>Posts</p>
                    <p onClick={() => profileMenu('Comment')} className={menu === 'Comment' && 'active'}>Comments</p>
                    <p onClick={() => profileMenu('Polls')} className={menu === 'Polls' && 'active'}>Polls</p>
                    <p onClick={() => profileMenu('Portfolio')} className={menu === 'Portfolio' && 'active'}>Portfolio</p>
                    {profile.id === user.userId &&
                      <p onClick={() => profileMenu('Following')} className={menu === 'Following' && 'active'}>Following</p>
                    }
                    {profile.id === user.userId &&
                      <p onClick={() => profileMenu('Tracking')} className={menu === 'Tracking' && 'active'}>Tracking</p>

                    }
                  </div>
                </div>
              </div>
            </div>

            {((menu === 'Post' && post.length > 0) || (menu === 'Polls' && post.length > 0)) &&
              <div className="feed_wrapper">
                <Feed type={menu} data={post} />
              </div>
            }
            {((menu === 'Tracking' && post.length > 0)) &&
              <div className="feed_wrapper">
                <Feed type={menu} data={post} isTracking={unTrackHandler} />
              </div>
            }
            {menu === 'Comment' &&
              <div className="feed_wrapper">
                <>
                  {
                    cmtPost.map((item, i) => {
                      let tags = []
                      if (item['user_posts.tags']) {
                        tags = item['user_posts.tags'].split(',')
                      }

                      let dynamicP = item.content

                      if (item.type === 'Post' && (item.content && item.content.length > 500 && !postId.includes(item.id))) {
                        let newDyP = item.content.split('<p>')[1].replace('</p>', '')
                        if (newDyP.length > 500) {
                          dynamicP = "<p>" + substring(newDyP, 500) + "</p>"
                        } else {
                          dynamicP = "<p>" + newDyP + "</p>"
                        }
                      }

                      return (
                        <div className="post" id={i}>
                          <div className="post_header">
                            <div className="header_left">
                              <div className="img">
                                <a href={"/profile/" + item['registers.id']}>
                                  <img src={item['registers.image'] ? item['registers.image'] : "/profile.png"} alt="" />
                                </a>
                              </div>
                              <div className="name">
                                <a href={"/profile/" + item['registers.id']}>
                                  <p>{item['registers.username'] ? item['registers.username'] : 'Unknown'} · </p>
                                </a>
                              </div>
                              <div className="time">
                                <p>{feedTime(item['createdAt'], 'ago')}</p>
                              </div>
                            </div>
                          </div>
                          <div className="post_content_wrapper">
                            <div className="post_content">
                              <div className="post_title">
                                {item['user_posts.title'] && <h3><Link to={"/post/" + item['user_posts.id']}>{item['user_posts.title']}</Link></h3>}

                              </div>

                              {item.content &&
                                <div className="post_description mt-2">
                                  {dynamicP ? ReactHtmlParser(dynamicP) : ReactHtmlParser(item.content)}
                                </div>

                              }
                              {item['user_posts.image'] &&
                                <div className="post_img">
                                  <img src={item['user_posts.image']} alt="" />
                                </div>
                              }
                              {tags && <div className="post_tag">
                                {tags.map(tag => <Link to={"/tags/" + tag.trim().split(' ').join('-')}><p>{tag}</p></Link>)}
                              </div>

                              }


                              {(!postId.includes(item['user_posts.id']) && item['user_posts.content'] && item['user_posts.content'].length > 500) && <p className="read_more" onClick={() => readMore(item['user_posts.id'])}>Read More <img className="read_more_img" src="/angel.png" alt="" /></p>}
                            </div>

                            <div className="post_comments">
                              <div className="user">
                                <Link to={"/profile/" + profile.id}>
                                  <div className="img">
                                    <img src={profile.image ? profile.image : '/profile.png'} alt="" />
                                    <div className="line" style={{
                                      height: height
                                    }}></div>
                                  </div>
                                </Link>

                                <div className="name">
                                  <div className="user_data">
                                    <Link to={"/profile/" + profile.id}>  <span className='name'>{profile.username} · </span></Link>
                                    <span className='time'>{feedTime(item.createdAt, 'ago')}</span>
                                  </div>

                                  <div className="user_comment">
                                    {ReactHtmlParser(item.comment)}
                                    {item.image && <img className="comment_img" src={item.image} alt="" />}

                                  </div>

                                  <div className="comment_like">
                                    <div className="box">
                                      <img src="/up.png" alt="" /><span>{item.like}</span>
                                    </div>
                                    <div className={"box"}>
                                      <img src="/down.png" alt="" /><span>{item.dislike}</span>
                                    </div>

                                    <div className="box">
                                      <img src="/icons8-love-19-white.png" alt="" /><span>{item.super_like}</span>
                                    </div>

                                  </div>






                                </div>
                              </div>
                            </div >




                          </div >
                        </div>
                      )
                    })
                  }
                </>

              </div>
            }

            <div className="portfolio">

              {
                (menu === 'Portfolio' && loadPort && portfolio.length > 0) &&
                <div style={{ height: '250px', maxWidth: '350px', marginTop: '20px' }}>
                  <ChartDonut
                    constrainToVisibleArea
                    data={
                      // [{ x: 'Apple', y: 35 }, { x: 'Amazon', y: 55 }, { x: 'Google', y: 10, }, { x: 'Twitter', y: 2 }]
                      portfolio.map(item => {
                        return { x: portfolioObj[item].name, y: parseInt(portfolioObj[item].value) }
                      })
                    }
                    labels={({ datum }) => `${datum.x}: ${datum.y}%`}
                    legendData={
                      // [{ name: 'Apple: 35' }, { name: 'Amazon: 55' }, { name: 'Google: 10', }, { name: 'Twitter 50' }]
                      portfolio.map(item => {
                        return { name: portfolioObj[item].name }
                      })
                    }
                    legendOrientation="vertical"
                    legendPosition="right"
                    padding={{
                      bottom: 20,
                      left: 20,
                      right: 140, // Adjusted to accommodate legend
                      top: 20
                    }}
                    // themeColor={ChartThemeColor.multiOrdered}
                    width={350}
                    cornerRadius={10}

                  />


                  {/* <ChartDonut
                    ariaDesc="Average number of pets"
                    ariaTitle="Donut chart example"
                    constrainToVisibleArea
                    data={[{ x: 'Cats', y: 5 }, { x: 'Dogs', y: 80 }, { x: 'Birds', y: 10 }]}
                    labels={({ datum }) => `${datum.x}: ${datum.y}`}
                  /> */}

                </div>
              }

              {(loadPort && portfolio.length === 0 && menu === 'Portfolio') &&

                <div className="portfolio_wrapper">
                  <div className="alert alert-danger">
                    {!user.userId ? notLoginText : 'The member has not provided portfolio information'}
                  </div>
                </div>

              }

            </div>

            {
              (menu === 'Tracking' && post.length === 0) &&
              <div class="portfolio_wrapper"><div class="alert alert-danger">{!user.userId ? notLoginText : 'No tracking yet'}</div></div>
            }
            {
              (menu === 'Post' && post.length === 0) &&
              <div class="portfolio_wrapper"><div class="alert alert-danger">{!user.userId ? notLoginText : 'No posts yet'}</div></div>
            }
            {
              (menu === 'Polls' && post.length === 0) &&
              <div class="portfolio_wrapper"><div class="alert alert-danger">{!user.userId ? notLoginText : 'No posts yet'}</div></div>
            }
            {
              (menu === 'Comment' && cmtPost.length === 0) &&
              <div class="portfolio_wrapper"><div class="alert alert-danger">{!user.userId ? notLoginText : 'No comments yet'}</div></div>
            }

            {
              (menu === 'Following' && following.length === 0) &&
              <div class="portfolio_wrapper"><div class="alert alert-danger">{!user.userId ? notLoginText : 'You are currently not following anyone'}</div></div>
            }
            <div className="following_row">
              {
                (menu === 'Following' && following.length > 0) && following.map(item => {
                  return (
                    <div className="follow_box">
                      <img src={item['registers.image'] ? item['registers.image'] : "/profile.png"} alt="" />
                      <p className='name'><a href={"/profile/" + item['registers.id']}>{item['registers.username']}</a></p>
                      <p className='button' onClick={() => unFollowHandler(item['registers.id'])}>{unfollow[item['registers.id']] ? 'Follow' : 'Following'}</p>
                    </div>
                  )
                })
              }

            </div>

          </div>
        }
      </Header>
    </>
  )
}
