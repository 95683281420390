export default function Logout() {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('jay.com')
    window.location.href = '/signin'
  }

  return (
    <>

    </>
  )
}