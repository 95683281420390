import { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../helpers/url";
import Layout from "./layout";

const Frontpage = () => {
  const [user, setUser] = useState({})
  const [tags, setTags] = useState('')
  useEffect(() => {
    axios.get(url + '/admin/front/')
      .then(({ data }) => {
        if (data.success) {
          setUser({
            email: data.user.email,
            name: data.user.name,
            username: data.user.username,
          })
          setTags(data.tags)
        }

      })
  }, [])
  const tagHandler = (e) => {
    setTags({ ...tags, tags: e.target.value })
  }
  const userHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const [proSuc, setProSuc] = useState(false)
  const [tagSuc, setTagSuc] = useState(false)
  const tagForm = (e) => {
    e.preventDefault()
    axios.post(url + '/admin/tag/', tags)
      .then(({ data }) => {
        if (data.success) {
          setTagSuc(true)
        }
      })
  }


  return (
    <Layout>
      <div className="admin_form">
        <div className="input_items">
          {tagSuc && <div className="alert alert-success">
            The tags have been updated.
          </div>}

          {!tagSuc &&
            <form onSubmit={tagForm}>
              <div className="input_group">
                <label htmlFor="">Write the tags (use comma to separate)</label>
                <input onChange={tagHandler} type="text" name="" id="" value={tags.tags} />
              </div>
              <div className="input_group">
                <button>Update Data</button>
              </div>
            </form>
          }
        </div>

      </div>


    </Layout>
  );
}

export default Frontpage;