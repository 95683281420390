import { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../helpers/url";
import Layout from "./layout";

const Frontpage = () => {
  const [user, setUser] = useState({})
  useEffect(() => {
    axios.get(url + '/admin/front/')
      .then(({ data }) => {
        if (data.success) {
          setUser({
            email: data.user.email,
            name: data.user.name,
            username: data.user.username,
          })
        }
      })
  }, [])

  const userHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const [proSuc, setProSuc] = useState(false)
  const profileForm = (e) => {
    e.preventDefault()
    axios.post(url + '/profile/update/', user)
      .then(({ data }) => {
        if (data.success) {
          setProSuc(true)
        }
      })
  }

  return (
    <Layout>
      <div className="admin_form">
        <div className="input_items">
          {proSuc &&
            <div className="alert alert-success">
              Your profile has been updated.
            </div>
          }
          {!proSuc &&
            <form onSubmit={profileForm}>
              <div className="input_group">
                <label htmlFor="">Email</label>
                <input required onChange={userHandler} value={user.email} type="text" name="email" id="" />
              </div>
              <div className="input_group">
                <label htmlFor="">Password</label>
                <input required onChange={userHandler} value={user.password} type="password" name="password" id="" />
              </div>
              <div className="input_group">
                <label htmlFor="">Name</label>
                <input required onChange={userHandler} value={user.name} type="text" name="name" id="" />
              </div>
              <div className="input_group">
                <label htmlFor="">Username</label>
                <input requiredt onChange={userHandler} value={user.username} type="text" name="username" id="" />
              </div>
              <div className="input_group">
                <button>Update Data</button>
              </div>
            </form>
          }
        </div>
      </div>


    </Layout>
  );
}

export default Frontpage;