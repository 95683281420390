import Header from '../helpers/header/header'
import Feed from '../components/feed/feed'
import Model from '../components/model/model';
import ModelReadMore from '../components/model/read_more_model';
import { useState, useEffect, useContext } from 'react'
import { url } from '../helpers/url';
import axios from 'axios';
import { Context } from '../helpers/context';
function Recent() {
  const [load, setLoad] = useState(false)
  const [post, setPost] = useState([])
  const [user, setUser] = useContext(Context)
  useEffect(() => {
    axios.get(url + `/feed?offset=0&recent=true`)
      .then(({ data }) => {
        if (data.success) {
          setPost(data.rows)
        }
        setLoad(true)
      })
  }, [])
  return (
    <>
      <Header>
        <div className="col-lg-9 body_col">
          {(load && post.length === 0) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && post.length > 0) && <>
            <Feed type="post" data={post} />
          </>
          }

        </div>
      </Header>
      {user.loginPopup && <Model style={"block"} data={() => { }} type={"login"} />}
      {user.read_more && <ModelReadMore />}
    </>
  );
}

export default Recent;