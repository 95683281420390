import Header from '../helpers/header/header'
import { useState, useEffect, useContext } from 'react'
import { url } from '../helpers/url';
import axios from 'axios';
import Pagination from '../admin-component/pagination'
import { Link } from 'react-router-dom';
import { todayIs, todayAsia, todayUs } from '../helpers/functions'
import ModelReadMore from '../components/model/trade_model'
import { Context } from '../helpers/context';

function Recent() {
  const [load, setLoad] = useState(false)
  const [row, setRow] = useState([])
  const [count, setCount] = useState([])
  const [oldRow, setOldRow] = useState([])
  const [oldCount, setOldCount] = useState([])
  const [user, setUser] = useContext(Context)
  // const [marketDate, setMarketDate] = useState()

  useEffect(() => {
    axios.get(url + `/post/glory?page=0&type=trade`)
      .then(({ data }) => {
        if (data.success) {
          setRow(data.rows)
          setOldRow(data.rows)
          setCount(data.count)
          setOldCount(data.count)
          // let findDate = data.rows.find(item => item.method === 'Dynamic')
          // if (findDate) {
          //   setMarketDate(todayUs(findDate.updatedAt))
          // }
        }
        setLoad(true)
      })
  }, [])


  function getPaginationId(id) {

    setLoad(false)
    const page = id > 0 ? id * 20 : 0
    axios.get(`${url}/post/glory?page=${page}&mode=view&type=trade`)
      .then(({ data }) => {
        setRow(data.rows);
        setOldRow(data.rows)
        setCount(data.count);
        setOldCount(data.count);
        setLoad(true);
      })
  }

  return (
    <>
      {(!user.userId && load) && <ModelReadMore />}
      <Header>
        <div className="col-lg-9 body_col glory">
          <h3 className='title'>Current Trades</h3>
          {/* {(load && marketDate && user.userId) &&
            <h4 className='title glory_sub'>Trade Performance as of close of market {marketDate}</h4>
          } */}
          {(load && row.length === 0 && user.userId) &&
            <div className="custom_alert alert alert-danger">
              No posts are found
            </div>
          }
          {(load && row.length > 0 && user.userId) && <>

            <table class="front_table table table-hover table-striped left_table table-dark">
              <thead>
                <tr>
                  <th>Entry Date</th>
                  <th>Member</th>
                  {/* <th>Trade</th> */}
                  <th>Trade Description</th>
                  <th>Returns (%)</th>
                  <th>As of Date</th>
                  <th>View Post</th>
                </tr>
              </thead>
              <tbody>


                {row.map((item, index) => {
                  const tradeObj = JSON.parse(item.tradeObj)
                  const keys = Object.keys(tradeObj)
                  return keys.map(t => {
                    return (
                      <tr key={item.id}>
                        <td>{todayIs(item.createdAt)}</td>
                        <td>{item['registers.username']}</td>
                        {/* <td>{item.trade}</td> */}
                        <td>{tradeObj[t].trade_des}</td>

                        <td className={((tradeObj[t].update || tradeObj[t].method === 'Manual') && tradeObj[t].percentage) && Math.sign(tradeObj[t].percentage) === -1 ? 'gain_percentage negetive' : 'gain_percentage'}>{(tradeObj[t].update || tradeObj[t].method === 'Manual') ? parseFloat(tradeObj[t].percentage).toFixed(2) + '%' : 'Pending'}</td>
                        <td>{todayIs(item.updatedAt)}</td>

                        <td><Link target="_blank" className="btn glory_btn" to={"/post/" + item.id}>View Post</Link></td>
                      </tr>
                    )
                  })
                })}
              </tbody>
            </table>
          </>
          }
          {count.length > 0 && <Pagination row={row.length} count={count} clickId={getPaginationId} />}
        </div>
      </Header>


    </>
  );
}

export default Recent;