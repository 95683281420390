import React, { useState, createContext } from "react";
export const Context = React.createContext();
const Store = ({ children }) => {
  const [type, setType] = useState({

  });
  return (
    <Context.Provider value={[type, setType]} >
      {children}
    </Context.Provider >
  )
}

export default Store;